import {getCharacter, getStaticData} from "../../redux/actions";
import {connect} from "react-redux";
import Characteristic from "./Characteristic";
import {useState} from "react";
import _ from "lodash";
import PlanModal from "../PlanModal/PlanModal";
import {saveCharacter} from "../../utils/fetcher";
import Influence from "./Influence";

function Characteristics({character, characteristics, getCharacter}){
  const [planEditModal, setPlanEditModal] = useState(false);
  const [editingCharData, setEditingCharData] = useState(null);
  if (!character || !characteristics) {
      return 'prrrrt'
  }

  const addPlan = (priority, comment) => {
    let charCopy = _.cloneDeep(character);
    let {char, charCosts} = editingCharData;
    let charChar = _.find(charCopy.characteristics, {id: char.id})
    if (priority === null){
      return
    }
    priority = parseInt(priority);
    charChar.plans = [{
      comment: comment,
      priority: priority,
      updateNumber: char.howManyUpdates+1,
      xp: charCosts.xpCostArray[char.howManyUpdates+1]
    }]
    saveCharacter(charCopy, getCharacter)
    setPlanEditModal(false)
  }

  const removePlan = () => {
    let charCopy = _.cloneDeep(character);
    let {char, charCosts} = editingCharData;
    let charChar = _.find(charCopy.characteristics, {id: char.id})
    charChar.plans = []
    saveCharacter(charCopy, getCharacter)
    setPlanEditModal(false)
  }

  const openModalForChar = (char, charCosts) => {
    setPlanEditModal(true);
    setEditingCharData({char, charCosts});
  }

  const getRow = (char) => {
    let charData = _.find(characteristics, {'id': char.characteristicId})
    if (charData.firstAptitudeId === 0 && charData.secondAptitudeId === 0) {
      return  <Influence key={char.id} char={char} editPlanModal={openModalForChar}/>
    }
    return <Characteristic key={char.id} char={char} editPlanModal={openModalForChar}/>
  }

  return <>
    <table className='erebus-skills' >
      <tbody>
        { character.characteristics.map(getRow) }
      </tbody>
    </table>
    {planEditModal &&
    <PlanModal
      visible={planEditModal}
      charData={editingCharData}
      close={() => setPlanEditModal(!planEditModal)}
      plan={editingCharData.char.plans[0] || {}}
      name={ _.find(characteristics, {id: editingCharData.char.characteristicId}).name }
      submit={addPlan}
      remove={removePlan}
    />
  }

  </>
}

const mapStateToProps = ({character, characteristics}) => {
  return {
    character,
    characteristics,
  }
};

const mapDispatchToProps = (dispatch) => ({
  getStaticData: (...args) => dispatch(getStaticData(...args)),
  getCharacter: (...args) => dispatch(getCharacter(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Characteristics);
