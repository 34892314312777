import {useLocation, useNavigate} from "react-router-dom";
import {getTokenFromKeycloak} from "../../utils/keycloakApi";
import {getStaticData} from "../../redux/actions";
import {isLoggedIn} from "../../utils/fetcher";
import LoginComponent from "./LoginComponent";
import * as React from "react";
import {connect} from "react-redux";

function LoginController({children, getStaticData}){
  const navigate = useNavigate();
  const location = useLocation()
  const params = new URLSearchParams(location.hash);

  if (params.get('code')){
    // if we have code in url, dump its contents to local storage, then redirect to pure login page
    getTokenFromKeycloak(params.get('code'), getStaticData)
      .then( data => {
        navigate('/items')
      })
  }
  if (!isLoggedIn()){
    return <LoginComponent />
  }
  return children
}

const mapDispatchToProps = (dispatch) => ({
  getStaticData: (...args) => dispatch(getStaticData(...args)),
});

export default connect(
  null,
  mapDispatchToProps,
)(LoginController);
