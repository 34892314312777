import {connect} from "react-redux";
import {getCharacter, getStaticData} from "../../redux/actions";
import _ from "lodash";
import {Button, Checkbox, Collapse} from "antd";
import {saveCharacter} from "../../utils/fetcher";
import {PlusSquareOutlined} from "@ant-design/icons";
import {requiredProps} from "../../utils/requiredProps";

const { Panel } = Collapse;

function PlanControl({charPower, power, editPlanModal}){
  if (charPower){
    if (_.get(charPower, 'plans[0]') !== undefined){
      return <div onClick={() => editPlanModal(power, _.get(charPower, 'id'))}>Plan Priority: {_.get(charPower, 'plans[0].priority')}</div>
    }
  }
  return <Button onClick={() => editPlanModal(power, _.get(charPower, 'id'))}><PlusSquareOutlined /> Add Plan</Button>
}

function Power({character, power, getCharacter, checkedRequirements, editPlanModal}) {
  if (requiredProps(character, power, checkedRequirements)) {
    return 'bzzzt'
  }

  function handleBuyPower(power, character, characterPower) {
    let powerCost = power.xp;
    if (powerCost > character.xpToSpend){
      alert(`Not enough xp to buy this power. you need ${powerCost}`)
      return
    }

    let char = _.cloneDeep(character)
    let charPower = _.find(char.powers, {powerId: power.id}) || {
      powerId: power.id,
      plans: [],
    };
    charPower.howMany = 1;
    charPower.plans = [];
    char.xpSpent += powerCost
    char.xpToSpend -= powerCost
    // if charTalent has no id, has to be added to characters list of skills
    if (!charPower.id){
      char.powers.push(charPower)
    }
    saveCharacter(char, getCharacter)
  }

  function handleRemovePower(power, character, characterPower) {
    let powerCost = power.xp;

    let char = _.cloneDeep(character)
    let charPower = _.find(char.powers, {powerId: power.id}) || {
      powerId: power.id,
      plans: [],
    };
    charPower.howMany = 0;
    charPower.plans = [];
    char.xpSpent -= powerCost
    char.xpToSpend += powerCost

    saveCharacter(char, getCharacter)
  }

  let req = _.find(checkedRequirements.powerRequirementsMeetDataSet, {powerId: power.id})

  let characterPower = _.find(character.powers, {powerId: power.id});
  let disabled = !_.get(req , 'meetsRequirements') //|| !(characterPower && characterPower.howMany)

  return <tr>
    <td>
      <Collapse>
      <Panel header={`${power.name}` } key="1">
        <p>{power.briefDescription}</p>
        <p>{power.fullDescription}</p>
        {
          power.requirementsLabel &&
          <p>Requires: {power.requirementsLabel}</p>
        }
      </Panel>
    </Collapse>
    </td>
    <td>{
      <Checkbox
        disabled={disabled}
        checked={characterPower && characterPower.howMany}
        onClick={() => {
          if (characterPower && characterPower.howMany) {
            handleRemovePower(power, character, characterPower)
          } else {
            handleBuyPower(power, character, characterPower)
          }
        }
      }
      />
    }
    </td>
    <td>{power.xp}</td>
    <td><PlanControl charPower={characterPower} {...{power, editPlanModal}}/> </td>
  </tr>
}


const mapStateToProps = ({character, checkedRequirements}) => {
  return {
    character,
    checkedRequirements,
  }
};

const mapDispatchToProps = (dispatch) => ({
  getStaticData: (...args) => dispatch(getStaticData(...args)),
  getCharacter: (...args) => dispatch(getCharacter(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Power);
