import _ from "lodash";
import {getStaticDataFromApi} from "./characterDataFetcher";

function buildBody(details){
  let formBody = [];
  for (const property in details) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  return formBody.join("&");
}

export const keycloak_url = `${process.env.REACT_APP_KEYCLOAK_URL}realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/auth?` +
`client_id=${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}&` +
`redirect_uri=${process.env.REACT_APP_KEYCLOAK_REDIRECT_URL}` +
`&response_mode=fragment&response_type=code&scope=openid&`



export const keycloak_logout_url = `${process.env.REACT_APP_KEYCLOAK_URL}realms/${process.env.REACT_APP_KEYCLOAK_REALM}/` +
`protocol/openid-connect/logout?redirect_uri=${process.env.REACT_APP_KEYCLOAK_REDIRECT_URL}`;


export function getTokenFromKeycloak(code, getStaticData){
  if (!code ){
    return
  }

  let details = {
    'grant_type': 'authorization_code',
    'client_id': 'WebApp',
    'client_secret': '1d0e6b9d-f5e8-417e-8162-3adace759032',
    'code': code,
    'redirect_uri': process.env.REACT_APP_KEYCLOAK_REDIRECT_URL,
  };

  let formBody = buildBody(details)

  return fetch(process.env.REACT_APP_KEYCLOAK_TOKEN_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    },
    body: formBody,
  })
  .then((response) => {
      if (response.ok){
          return response.json()
      }
      return response.json().then(error => {
          if (_.get(error, 'errors') && _.get(error, 'title')){
            alert(JSON.stringify(error.errors))
          }
          console.error(error);
      })
  })
  .then(resp => {
    for (const property in resp) {
      window.localStorage.setItem(property, resp[property])
    }
    // setInterval(refreshToken, 5000);
    getStaticDataFromApi(getStaticData);
  })
}

export function refreshToken(){
  let details = {
    'grant_type': 'refresh_token',
    'client_id': 'WebApp',
    'client_secret': '1d0e6b9d-f5e8-417e-8162-3adace759032',
    'refresh_token': window.localStorage.getItem('refresh_token')
  };

  let formBody = buildBody(details)

  return fetch(process.env.REACT_APP_KEYCLOAK_TOKEN_URL, {
    method: 'POST',
    headers: {
      // 'Authorization': `Basic ${btoa('WebApp:1d0e6b9d-f5e8-417e-8162-3adace759032')}`,
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: formBody,
  })
  .then((response) => {
      if (response.ok){
          return response.json()
      }
      return response.json().then(error => {
          if (_.get(error, 'errors') && _.get(error, 'title')){
            alert(JSON.stringify(error.errors))
          }
          console.error(error);
      })
  })
  .then(resp => {
    console.log(resp)
    for (const property in resp) {
      window.localStorage.setItem(property, resp[property])
    }
  })
}