import {getCharacter, getStaticData} from "../../redux/actions";
import {connect} from "react-redux";
import Talent from "./Talent";
import _ from "lodash";
import CustomTalent from "./CustomTalent";
import {useState} from "react";
import {saveCharacter} from "../../utils/fetcher";
import PlanModal from "../PlanModal/PlanModal";
import FilterButton from "../FilterButton";
import './styles.css';
import {requiredProps} from "../../utils/requiredProps";
import mergeTalents from "../../utils/mergeTalents";

const BOUGHT_FILTER_NAME = 'bought'
const REQUIREMENTS_FILTER_NAME = 'requirement_met'
const AFFORDABLE_FILTER_NAME = 'affordable'

function Talents({mergedTalents, character, getCharacter, talentsCosts}){
  const [planEditModal, setPlanEditModal] = useState(false);
  const [editingTalentData, setEditingTalentData] = useState(null);
  const [filters, setFilters] = useState(new Set());

  if (requiredProps(character, mergedTalents)) {
    return 'prrrt'
  }


  const flipFilter = (filterName) => {
    let filterCopy = _.cloneDeep(filters)
    if (filterCopy.has(filterName)){
      filterCopy.delete(filterName)
    } else {
      filterCopy.add(filterName)
    }
    setFilters(filterCopy)
  }

  const addPlan = (priority, comment) => {
    if (priority === null){
      return
    }
    priority = parseInt(priority);
    let charCopy = _.cloneDeep(character);
    let {talent, costs, charTalentId} = editingTalentData
    let charTalent =  charTalentId ? _.find(charCopy.talents, {id: charTalentId}) : _.find(charCopy.talents, {talentId: talent.id})
    if (!charTalent){
      charTalent = {
        talentId: talent.id,
        plans: [],
        rank: 0,
      }
      charCopy.talents.push(charTalent)
    }

    charTalent.plans = [{
      comment: comment,
      priority: priority,
      updateNumber: 1,
      xp: costs
    }]
    saveCharacter(charCopy, getCharacter)
    setPlanEditModal(false)
  }

  const removePlan = () => {
    let charCopy = _.cloneDeep(character);
    let {talent, charTalentId} = editingTalentData
    let charSkill =  charTalentId ? _.find(charCopy.talents, {id: charTalentId}) : _.find(charCopy.talents, {talentId: talent.id})
    charSkill.plans = []
    saveCharacter(charCopy, getCharacter)
    setPlanEditModal(false)
  }

  const openModalForTalent = (talent, costs, charTalentId) => {
    setPlanEditModal(true);
    setEditingTalentData({talent, costs, charTalentId});
  }

  const getPlan = () => {
    let {talent, costs, charTalentId} = editingTalentData;
    let charTalent =  charTalentId ? _.find(character.talents, {id: charTalentId}) : _.find(character.talents, {talentId: talent.id})
    return _.get(charTalent, 'plans[0]', {})
  }

  const getFilteredTalents = () => {
    let filteredTalents = mergedTalents
    if (filters.has(BOUGHT_FILTER_NAME)){
      filteredTalents = _.filter(filteredTalents, item => {
        return _.get(item, 'charTalent.howMany', 0) !== 0
      })
    }
    if (filters.has(REQUIREMENTS_FILTER_NAME)){
      filteredTalents = _.filter(filteredTalents, item => {
        return _.get(item, 'meetsRequirements', false)
      })
    }
    if (filters.has(AFFORDABLE_FILTER_NAME)){
      filteredTalents = _.filter(filteredTalents, item => {
        return item.talentCost <= character.xpToSpend
      })
    }

    return _.sortBy(filteredTalents, talent => talent.talent.name)
  }

  return <>
    <FilterButton
      filters={filters}
      filterText={'Bought'}
      filterName={BOUGHT_FILTER_NAME}
      flipFilter={flipFilter}
    />
    <FilterButton
      filters={filters}
      filterText={'Requirements met'}
      filterName={REQUIREMENTS_FILTER_NAME}
      flipFilter={flipFilter}
    />
    <FilterButton
      filters={filters}
      filterText={'Affordable'}
      filterName={AFFORDABLE_FILTER_NAME}
      flipFilter={flipFilter}
    />
    <table className='char-table talents-table erebus-talents'>
    <thead>
      <tr>
        <th>Name</th>
        <th>Bought</th>
        <th>Cost</th>
        <th>Plan</th>
      </tr>
    </thead>
    <tbody>{
      getFilteredTalents().map((mergedTalent, idx) =>{
        if(mergedTalent.talent && mergedTalent.talent.isSpecialist && !mergedTalent.charTalent){
          return <CustomTalent key={idx} {...mergedTalent}/>
        }
        return <Talent key={idx} {...mergedTalent} editPlanModal={openModalForTalent}/>
    } ) }
    </tbody>
  </table>
  {planEditModal &&
    <PlanModal
      visible={planEditModal}
      charData={editingTalentData}
      close={() => setPlanEditModal(false)}
      plan={getPlan()}
      name={ editingTalentData.talent.name }
      submit={addPlan}
      remove={removePlan}
    />}
  </>
}


const mapStateToProps = ({character, talents, checkedRequirements, talentsCosts}) => {
  let mergedTalents = []
  if (character && talents && checkedRequirements && talentsCosts ) {
    talents.map((talent) => mergeTalents(talent, character, checkedRequirements, mergedTalents, talentsCosts))
  }

  return {
    character,
    talentsCosts,
    mergedTalents
  }
};

const mapDispatchToProps = (dispatch) => ({
  getStaticData: (...args) => dispatch(getStaticData(...args)),
  getCharacter: (...args) => dispatch(getCharacter(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Talents);
