import {connect} from "react-redux";
import {Collapse, List} from "antd";
const { Panel } = Collapse;


function HistoryList({characterHistory}){
  return <Collapse
      className={`talent-title site-collapse-custom-collapse`}
    >
      <Panel
        header={'History'}
        key="1"
        classname={'site-collapse-custom-panel'}
      >
        <List
          style={{textAlign: 'left'}}
          size="small"
          bordered
          dataSource={characterHistory}
          renderItem={(item) => {
            let changes = Object.entries(item.data).map(([field, changes]) => {
              return `${field}  ${changes.old_val ? 'from ' + changes.old_val[0] : ''} ${changes.new_val ? 'to ' + changes.new_val[0] + ', ' : ''}`
            })
            return <List.Item>
              {item.item_descr}: {item.event_type}; {changes} at {item.timestamp}
            </List.Item>}
          }/>
      </Panel>
    </Collapse>
}

const mapStateToProps = ({characterHistory}) => {
  return {
    characterHistory,
  }
};

export default connect(
  mapStateToProps,
)(HistoryList);