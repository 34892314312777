import {getCharacter, getStaticData} from "../../../redux/actions";
import {connect} from "react-redux";
import Talent from "./PowerSML";
import _ from "lodash";
import './styles.css';
import '../items.css';
import {requiredProps} from "../../../utils/requiredProps";
import {Card} from "antd";
import PowerSML from "./PowerSML";


function PowersSML({character, powers}){

  if (requiredProps(character, powers)) {
    return 'prrrt'
  }

  const getFilteredPowers = () => {
    let filtered = powers
    filtered = _.filter(filtered, power => {
      let charPow = _.find(character.powers, {powerId: power.id});
      return charPow && charPow.howMany
    })

    return _.sortBy(filtered, power => power.discipline)
  }

  const filteredPowers = getFilteredPowers()

  if (!filteredPowers.length){
    return null;
  }

  return <Card
    title={"Powers"}
    className={'talents-card'}
    size={'small'}
  >
    <table className='talents-table-sml erebus-powers-sml'>
      <thead>
        <tr>
          <th>Name</th>
        </tr>
      </thead>
      <tbody>{
        filteredPowers.map((power, idx) =>{
          return <PowerSML key={idx} power={power} />
      } ) }
      </tbody>
    </table>
  </Card>
}

const mapStateToProps = ({character, powers}) => {
  return {
    character, powers
  }
};

const mapDispatchToProps = (dispatch) => ({
  getStaticData: (...args) => dispatch(getStaticData(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PowersSML);
