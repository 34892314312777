import {getCharacter, getStaticData} from "../../redux/actions";
import {connect} from "react-redux";
import _ from "lodash";
import {saveCharacter} from "../../utils/fetcher";
import {getCharacterSpecificData} from "../../utils/characterDataFetcher";
import {Button, Dropdown, Menu} from "antd";
import './styles.css'
import {MenuOutlined} from "@ant-design/icons";
import {useState} from "react";

function CharacterOrSelect({allCharacters, selectedCharacter, character, getStaticData, getCharacter, openHamburger, flipHamburger}){

  const handleAddXp = () => {
    let xpToAdd = prompt("How much xp to add?", 0);
    if (xpToAdd === null){
      return
    }
    xpToAdd = parseInt(xpToAdd);
    if(xpToAdd && xpToAdd % 50 === 0){
      let char = _.cloneDeep(character);
      char.xpToSpend += xpToAdd
      saveCharacter(char, getCharacter)
    }
    else {
      alert('HERESY')
    }
  }

  const  handleCharacterSelect = (char) => {
    localStorage.setItem('selectedCharacterId', char.id);
    getStaticData('selectedCharacter', char)
    getCharacterSpecificData(char.id, getStaticData)
  }

  if (!allCharacters) {
    return null
  }

  let menuItems = allCharacters.map(obj =>
    <Menu.Item onClick={() => {handleCharacterSelect(obj)}}
      key={obj.id}
    >
      {obj.name}
    </Menu.Item>
  );

  let menu = <Menu>{ menuItems }</Menu>;

  let headerContent = <div>Please Select a character</div>
  if(selectedCharacter && character){
    headerContent = <div className={`character-header-content ${ !openHamburger ? 'div-display-inline' : ''}`}>
      <div className={`character-name`}>{character.name}</div>
      <div className={`character-xp-to-spend-container ${ openHamburger ? 'div-display' : ''}`}>XP to spend: <span className='character-xpToSpend'>{character.xpToSpend}</span> <Button style={{padding: '4px, 0'}} onClick={handleAddXp}>+</Button></div>
      <div className={`character-xp-spent-container ${ openHamburger ? 'div-display' : ''}`}>XP spent: <span className='character-xpSpent'>{character.xpSpent} </span></div>
    </div>
  }

  return <>
    <Dropdown className={'sider-header'} overlay={menu} placement="bottomLeft" arrow>
      {headerContent}
    </Dropdown>
      <MenuOutlined onClick={() => flipHamburger(!openHamburger)}/>
    </>
}

const mapStateToProps = ({allCharacters, selectedCharacter, character}) => {
  return {
    allCharacters,
    selectedCharacter,
    character,
  }
};

const mapDispatchToProps = (dispatch) => ({
  getStaticData: (...args) => dispatch(getStaticData(...args)),
  getCharacter: (...args) => dispatch(getCharacter(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CharacterOrSelect);
