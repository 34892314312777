import {connect} from "react-redux";
import './styles.css';


function TalentSML({talent, charTalent,}) {
  return <tr>
    <td>
      {`${talent.name} ${talent.isSpecialist && charTalent ? charTalent.speciality : ''}` }
    </td>
  </tr>
}



const mapStateToProps = ({character}) => {
  return {
    character,
  }
};


export default connect(
  mapStateToProps,
)(TalentSML);
