import {Col, Input} from "antd";
import {DeleteOutlined, ScissorOutlined} from "@ant-design/icons";
import * as React from "react";

export function CardTitle({title, toggleEdit}) {
    return <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <span>{title}</span>

        <ScissorOutlined style={{ fontSize: '15px' }} onClick={toggleEdit} />
        </div>
}
export function CardTitleEdit({title, handleDelete, toggleEdit, onChange}) {
    return <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Input size={'small'} value={title} onChange={onChange}/>
        <div>
            <DeleteOutlined style={{ fontSize: '15px' }} onClick={handleDelete} />
            <ScissorOutlined style={{ fontSize: '15px' }} onClick={toggleEdit} />
        </div>
    </div>
}
