import {getCharacter, getStaticData} from "../../redux/actions";
import {connect} from "react-redux";
import Skill from "./Skill";
import _ from "lodash";
import CustomSkill from "./CustomSkill";
import {useState} from "react";
import {saveCharacter} from "../../utils/fetcher";
import PlanModal from "../PlanModal/PlanModal";

export function getSkillsWithChar(character, skills) {

  let charSkills = []
  skills.map((skill) => {
    if(skill.isSpecialist){
      charSkills.push(
        {
          skill: skill,
          char_skill: null,
        }
      )
      let found_skills = _.filter(character.skills, {skillId: skill.id})
      if(found_skills) {
        found_skills.map(char_skill => {
          charSkills.push({
            skill: skill,
            char_skill: char_skill,
          })
        })
      }

    } else {
      let char_skill = _.find(character.skills, {skillId: skill.id})
      charSkills.push(
        {
          skill: skill,
          char_skill: char_skill,
        }
      )
    }
  })
  return charSkills
}

function Skills({character, skills, getCharacter}){
  const [planEditModal, setPlanEditModal] = useState(false);
  const [editingSkillData, setEditingSkillData] = useState(null);

  if (!character || !skills) {
      return 'prrrrt'
  }

  const addPlan = (priority, comment) => {
    if (priority === null){
      return
    }
    priority = parseInt(priority);
    let charCopy = _.cloneDeep(character);
    let {skill, costs, charSkillId} = editingSkillData;
    let charSkill =  charSkillId ? _.find(charCopy.skills, {id: charSkillId}) : _.find(charCopy.skills, {skillId: skill.id})
    if (!charSkill){
      charSkill = {
        skillId: skill.id,
        plans: [],
        rank: 0,
      }
      charCopy.skills.push(charSkill)
    }

    charSkill.plans = [{
      comment: comment,
      priority: priority,
      updateNumber: charSkill.rank+1,
      rank: charSkill.rank+1,
      xp: costs.xpCostArray[charSkill.rank]
    }]
    saveCharacter(charCopy, getCharacter)
    setPlanEditModal(false)
  }

  const removePlan = () => {
    let charCopy = _.cloneDeep(character);
    let {skill, charSkillId} = editingSkillData;
    let charSkill =  charSkillId ? _.find(charCopy.skills, {id: charSkillId}) : _.find(charCopy.skills, {skillId: skill.id})
    charSkill.plans = []
    saveCharacter(charCopy, getCharacter)
    setPlanEditModal(false)
  }

  const openModalForSkill = (skill, costs, charSkillId) => {
    setPlanEditModal(true);
    setEditingSkillData({skill, costs, charSkillId});
  }
  const getPlan = () => {
    let {skill, costs, charSkillId} = editingSkillData;
    let charSkill =  charSkillId ? _.find(character.skills, {id: charSkillId}) : _.find(character.skills, {skillId: skill.id})
    return _.get(charSkill, 'plans[0]', {})
  }

  return <>
    <table className={'char-table erebus-skills'}>
      <thead>
        <tr>
          <th>Name</th>
          <th>Bought</th>
          <th>Cost</th>
          <th>Plan</th>
        </tr>
      </thead>
      <tbody>
      {
        getSkillsWithChar(character, skills).map(({skill, char_skill}, idx) =>{
            if(skill && skill.isSpecialist && !char_skill){
              return <CustomSkill key={idx} skill={skill} char_skill={char_skill} editPlanModal={openModalForSkill}/>
            }
            return <Skill key={idx} skill={skill} char_skill={char_skill} editPlanModal={openModalForSkill}/>
      } ) }
      </tbody>
    </table>
    {planEditModal &&
    <PlanModal
      visible={planEditModal}
      charData={editingSkillData}
      close={() => setPlanEditModal(false)}
      plan={getPlan()}
      name={ editingSkillData.skill.name }
      submit={addPlan}
      remove={removePlan}
    />}
  </>
}


const mapStateToProps = ({character, skills}) => {
  return {
    character,
    skills,
  }
};

const mapDispatchToProps = (dispatch) => ({
  getStaticData: (...args) => dispatch(getStaticData(...args)),
  getCharacter: (...args) => dispatch(getCharacter(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Skills);
