import _ from "lodash";

export default function mergeTalents(talent, character, checkedRequirements, mergedTalents, talentsCosts) {
  let req = _.find(checkedRequirements.talentRequirementsMeetDataSet, {talentId: talent.id})
  let meetsRequirements = _.get(req , 'meetsRequirements', true)
  let talentCost = _.find(talentsCosts, {talentId: talent.id}).xpCost;
  if(talent.isSpecialist){
    mergedTalents.push(
      {
        talent,
        charTalent: null,
        meetsRequirements,
        talentCost,
      }
    )
    let found_talents = _.filter(character.talents, {talentId: talent.id})
    if(found_talents) {
      found_talents.map(charTalent => {
        mergedTalents.push({
          talent,
          charTalent,
          meetsRequirements,
          talentCost,
        })
      })
    }

  } else {
    let charTalent = _.find(character.talents, {talentId: talent.id})
    mergedTalents.push(
      {
        talent,
        charTalent,
        meetsRequirements,
        talentCost,
      }
    )
  }
}