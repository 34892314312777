import {getStaticData} from "../../../redux/actions";
import {connect} from "react-redux";
import Skill from "./SkillSML";
import {getSkillsWithChar} from "../../Skills/Skills";
import {Card} from "antd";
import '../items.css';

function SkillsSML({character, skills}){
  if (!character || !skills) {
      return 'prrrrt'
  }
  return <Card
    className={'skills-card'}
    title={'Skills'}
    size={'small'}
  >
    <table className={'erebus-skills-sml'}>
      <thead>
        <tr>
          <th>Name</th>
          <th>Bought</th>
        </tr>
      </thead>
      <tbody>
      {
        getSkillsWithChar(character, skills).map(({skill, char_skill}, idx) => {
            if(skill && char_skill){
              return <Skill key={idx} skill={skill} char_skill={char_skill}/>
            }
            return null
      } ) }
      </tbody>
    </table>
  </Card>
}


const mapStateToProps = ({character, skills}) => {
  return {
    character,
    skills,
  }
};

const mapDispatchToProps = (dispatch) => ({
  getStaticData: (...args) => dispatch(getStaticData(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SkillsSML);
