import {getCharacter, getStaticData} from "../../redux/actions";
import {connect} from "react-redux";
import {Button, Input, InputNumber, List, Select, Space} from "antd";
import _ from "lodash";
import {useState} from "react";
import {saveCharacter} from "../../utils/fetcher";
import {Option} from "antd/es/mentions";

const debouncedEditField = _.debounce( (e, character, getCharacter, field) => {
  let charCopy = _.cloneDeep(character)
  _.set(charCopy, field, e)
  saveCharacter(charCopy, getCharacter)
  }, 2000
)

function XPIndependent({character, mutations, malignancies, getCharacter}){
  const [corruption, setCorruption] = useState(_.get(character, 'corruption', null));
  const [insanity, setInsanity] = useState(_.get(character, 'insanity', null));
  const [openMutations, setMutations] = useState(false);
  const [openMalignancies, setMalignancies] = useState(false);
  const [openDisorders, setDisorders] = useState(false);
  const [newDisorder, setNewDisorder] = useState();

  if (!character || !mutations || !malignancies ){
    return 'bzzzt'
  }

  if (character && corruption === null){
    setCorruption(_.get(character, 'corruption', 0))
    setInsanity(_.get(character, 'insanity', 0))
  }

  const editCorruption = (e) => {
    setCorruption(e)
    debouncedEditField(e, character, getCharacter, 'corruption')
  }

  const editInsanity = (e) => {
    setInsanity(e)
    debouncedEditField(e, character, getCharacter, 'insanity')
  }

  const handleMalignancySelect = id => {
    if (window.confirm(`This is irreversible! \n Are you sure you want to add: \n ${ _.find(malignancies, {id: id}).name } ? `)){
      let charCopy = _.cloneDeep(character)
      charCopy.malignancies.push({malignancyId: id})
      saveCharacter(charCopy, getCharacter)
    }
  }

  const handleMutationSelect = id => {

    if (window.confirm(`This is irreversible! \n Are you sure you want to add: \n ${ _.find(malignancies, {id: id}).name } ? `)) {
      let charCopy = _.cloneDeep(character)
      charCopy.mutations.push({mutationId: id})
      saveCharacter(charCopy, getCharacter)
    }
  }

  const handleDisorderSelect = () => {
    let charCopy = _.cloneDeep(character)
    charCopy.disorders.push({name: newDisorder, severity: 1})
    saveCharacter(charCopy, getCharacter)
  }

  return <div className={'XPIndependent'}>
    <Space direction={'vertical'}>
      <InputNumber className='erebus-corruption-input' addonBefore="Corruption points" value={corruption} onChange={editCorruption}/>
      <InputNumber className='erebus-insanity-input' addonBefore="Insanity points" value={insanity} onChange={editInsanity}/>
    </Space>
    <br/>
    <br/>
    <br/>
    <Space align="start">
      <Space direction={'vertical'} align="start">
        <>Mutations <Button onClick={()=> setMutations(!openMutations) }>{!openMutations ? '+' : '-'}</Button></>
        { openMutations &&
          <Select style={{width: 200}} onChange={handleMutationSelect}>
            {
              mutations.map(obj => <Option value={obj.id}>{obj.name}</Option>)
            }
          </Select>
        }
        <List
          size="small"
          bordered
          dataSource={character.mutations}
          renderItem={item => <List.Item>{_.find(mutations, {id: item.mutationId}).name}</List.Item>}
        />
      </Space>
      <Space direction={'vertical'} align="start">
        <>Malignancies <Button onClick={()=> setMalignancies(!openMalignancies) }>{!openMalignancies ? '+' : '-'}</Button></>
        { openMalignancies &&
          <Select style={{width: 200}} onChange={handleMalignancySelect}>
            {
              malignancies.map(obj => <Option value={obj.id}>{obj.name}</Option>)
            }
          </Select>
        }
        <List
          size="small"
          bordered
          dataSource={character.malignancies}
          renderItem={item => <List.Item>{_.find(malignancies, {id: item.malignancyId}).name}</List.Item>}
        />
      </Space>
      <Space direction={'vertical'} align="start">
        <>Mental disorders <Button onClick={()=> setDisorders(!openDisorders) }>{!openDisorders ? '+' : '-'}</Button></>
          { openDisorders &&
            <Input.Group compact>
              <Input
                value={newDisorder}
                onChange={({target}) =>{setNewDisorder(target.value)}}/>
              <Button type="primary" onClick={handleDisorderSelect}>Submit</Button>
            </Input.Group>
          }
        <List
          size="small"
          bordered
          dataSource={character.disorders}
          renderItem={item => <List.Item>{item.name}</List.Item>}
        />
      </Space>
    </Space>
  </div>
}


const mapStateToProps = ({character, mutations, malignancies}) => {
  return {
    character,
    mutations,
    malignancies,
  }
};

const mapDispatchToProps = (dispatch) => ({
  getStaticData: (...args) => dispatch(getStaticData(...args)),
  getCharacter: (...args) => dispatch(getCharacter(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(XPIndependent);
