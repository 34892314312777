import {Checkbox} from "antd";
import {connect} from "react-redux";
import {getCharacter, getStaticData} from "../../redux/actions";
import _ from "lodash";
import './styles.css'
import {PlusSquareOutlined} from "@ant-design/icons";
import {saveCharacter} from "../../utils/fetcher";


function get_class_name(charCosts) {
    if (!charCosts){
        return ''
    }

    const costs = charCosts.xpCostArray;

    let className = ''
    switch (costs[0]) {
      case 100:
        className = 'inner-green'
        break;
      case 250:
        className = 'inner-yellow'
        break;
      default:
        className = 'inner-red'
    }

    return className
}


function Characteristic({char, characteristics, characteristicsCosts, character, getCharacter, editPlanModal}) {
  if (!char || !characteristics || !characteristicsCosts){
      return null
  }

  const char_characteristic = _.find(characteristics, {id: char.characteristicId})
  const name = _.get(char_characteristic, 'name');

  function handleImproveChar(idx, characteristicsCosts, charCosts) {
    let charCopy = _.cloneDeep(character);
    let charChar = _.find(charCopy.characteristics, {id: char.id})
    let newVal = idx+1
    _.remove(charChar.plans, {updateNumber: newVal})
    charChar.howManyUpdates = newVal;
    charChar.value += 5
    charCopy.xpSpent += charCosts.xpCostArray[idx]
    charCopy.xpToSpend -= charCosts.xpCostArray[idx]

    saveCharacter(charCopy, getCharacter)
  }

  function handleDecreaseChar(idx, characteristicsCosts, charCosts) {
    let charCopy = _.cloneDeep(character);
    let charChar = _.find(charCopy.characteristics, {id: char.id})
    charChar.howManyUpdates = idx;
    charChar.value -= 5
    charCopy.xpSpent -= charCosts.xpCostArray[idx]
    charCopy.xpToSpend += charCosts.xpCostArray[idx]

    saveCharacter(charCopy, getCharacter)
  }

  function get_checkboxes(char, characteristicsCosts, charCosts){
    let checkboxes = [];
    for(let i=0; i < characteristicsCosts.maximumXpUpgrades; i++){
      if (i < char.howManyUpdates){
          checkboxes.push(
            <Checkbox
              checked={true}
              disabled={i!==char.howManyUpdates-1}
              onClick={() => handleDecreaseChar(i, characteristicsCosts, charCosts)}
            />)
      }
      else{
        checkboxes.push(
          <Checkbox
            checked={false}
            disabled={i !== char.howManyUpdates}
            onClick={() => handleImproveChar(i, characteristicsCosts, charCosts)}
          />)
      }
    }
    return checkboxes
  }


  const openPlanModal = () => {

  }

  const charCosts = _.find(characteristicsCosts.xpData, {characteristicId: char.characteristicId})
  return <tr>
    <td>{name}</td>
    <td><Checkbox className={get_class_name(charCosts)}/></td>
    <td>{char.value}</td>
    <td>{get_checkboxes(char, characteristicsCosts, charCosts)}</td>
    <td>{charCosts && char && charCosts.xpCostArray[char.howManyUpdates]}</td>
    <td onClick={() => editPlanModal(char, charCosts)}>
      {char.plans && char.plans.length ? <> Plan Priority:{char.plans[0].priority}</>:
      <div><PlusSquareOutlined />  Add Plan</div>}</td>
  </tr>
}



const mapStateToProps = ({characteristicsCosts, characteristics, character}) => {
  return {
    character,
    characteristics,
    characteristicsCosts,
  }
};

const mapDispatchToProps = (dispatch) => ({
  getStaticData: (...args) => dispatch(getStaticData(...args)),
  getCharacter: (...args) => dispatch(getCharacter(...args)),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Characteristic);
