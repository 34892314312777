import './App.css';
import 'antd/dist/antd.css';
import {Layout} from 'antd';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import {Characteristics, CharacterItems, NonModifiable, Sidebar, Skills, About} from './components'
import {useEffect} from "react";
import {isLoggedIn} from "./utils/fetcher";
import { getStaticData } from "./redux/actions";
import { connect } from "react-redux";
import SpecialAdvances from "./components/SpecialAdvances/Powers";
import XPIndependent from "./components/XPIndependent/XPIndependent";
import EliteAdvances from "./components/EliteAdvances/EliteAdvances";
import Talents from "./components/Talents/Talents";
import {getStaticDataFromApi} from "./utils/characterDataFetcher";
import * as React from "react";
import LoginController from "./components/Login/LoginController";
import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

const { Header, Content } = Layout;


function App( {getStaticData}) {
  useEffect(() => {
    // get list of all characters
    if (isLoggedIn()){
      getStaticDataFromApi(getStaticData)
    }
  });

  return (
    <div className="App">
      <Layout>
        <BrowserRouter>
          <LoginController>
            <Sidebar />
            <Layout>
            <Header>WH40k Character Manager</Header>
            <Content>
              <Routes>
                <Route exact path="/" element='Base'/>
                <Route path="non-modifiable"  element={<NonModifiable/>}/>
                <Route path="xp-independent"  element={<XPIndependent/>}/>
                <Route path="elite-advances"  element={<EliteAdvances/>} />
                <Route path="characteristics" element={<Characteristics/>} />
                <Route path="skills"          element={<Skills/>} />
                <Route path="talents"         element={<Talents/>} />
                <Route path="special-advances"  element={<SpecialAdvances/>} />
                <Route path="character_sheet" element={<CharacterItems/>} />
                <Route path="about"           element={<About/>} />
              </Routes>
            </Content>
          </Layout>
          </LoginController>
          <NotificationContainer/>
        </BrowserRouter>
      </Layout>
    </div>
  );
}


const mapDispatchToProps = (dispatch) => ({
  getStaticData: (...args) => dispatch(getStaticData(...args)),
});

export default connect(
  null,
  mapDispatchToProps,
)(App);
