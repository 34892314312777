import {getCharacter, getStaticData} from "../../redux/actions";
import {connect} from "react-redux";
import Power from "./Power";
import _ from "lodash";
import {saveCharacter} from "../../utils/fetcher";
import {useState} from "react";
import PlanModal from "../PlanModal/PlanModal";
import {requiredProps} from "../../utils/requiredProps";
import PowerFilters, {AFFORDABLE_FILTER_NAME, BOUGHT_FILTER_NAME, REQUIREMENTS_FILTER_NAME} from "./PowerFilters";

function Powers({character, powers, getCharacter, checkedPowerRequirements}){
  const [planEditModal, setPlanEditModal] = useState(false);
  const [editingPowerData, setEditingPowerData] = useState(null);
  const [filters, setFilters] = useState(new Set());

  if (requiredProps(character, powers)) {
    return 'aoixaosid'
  }

  const flipFilter = (filterName) => {
    let filterCopy = _.cloneDeep(filters)
    if (filterCopy.has(filterName)){
      filterCopy.delete(filterName)
    } else {
      filterCopy.add(filterName)
    }
    setFilters(filterCopy)
  }

  const addPlan = (priority, comment) => {
    if (priority === null){
      return
    }
    priority = parseInt(priority);
    let charCopy = _.cloneDeep(character);
    let {power, charPowerId} = editingPowerData;
    let charPower =  charPowerId ? _.find(charCopy.powers, {id: charPowerId}) : _.find(charCopy.powers, {powerId: power.id})
    if (!charPower){
      charPower = {
        powerId: power.id,
        plans: [],
        rank: 0,
      }
      charCopy.powers.push(charPower)
    }
    charPower.plans = [{
      comment: comment,
      priority: priority,
      updateNumber: 1,
      xp: power.xp
    }]
    saveCharacter(charCopy, getCharacter)
    setPlanEditModal(false)
  }

  const removePlan = () => {
    let charCopy = _.cloneDeep(character);
    let {power, charPowerId} = editingPowerData;
    let charSkill =  charPowerId ? _.find(charCopy.powers, {id: charPowerId}) : _.find(charCopy.powers, {powerId: power.id})
    charSkill.plans = []
    saveCharacter(charCopy, getCharacter)
    setPlanEditModal(false)
  }

  const openModalForPower = (power, charPowerId) => {
    setPlanEditModal(true);
    setEditingPowerData({power, charPowerId});
  }
  
  const getPlan = () => {
    let {power, charPowerId} = editingPowerData;
    let charSkill =  charPowerId ? _.find(character.powers, {id: charPowerId}) : _.find(character.powers, {powerId: power.id})
    return _.get(charSkill, 'plans[0]', {})
  }

  const filteredPowers = () => {
    if (filters.size === 0){
      return powers
    }
    let filtered = powers
    let filterCopy = _.cloneDeep(filters)
    if (filterCopy.has(BOUGHT_FILTER_NAME)){
      filterCopy.delete(BOUGHT_FILTER_NAME)
      filtered = _.filter(filtered, power => {
        let charPow = _.find(character.powers, {powerId: power.id});
        return charPow && charPow.howMany
      })
    }
    if (filterCopy.has(REQUIREMENTS_FILTER_NAME)){
      filterCopy.delete(REQUIREMENTS_FILTER_NAME)
      filtered = _.filter(filtered, power => {
         return _.get(_.find(checkedPowerRequirements, {powerId: power.id}), 'meetsRequirements', false);
      })
    }
    if (filterCopy.has(AFFORDABLE_FILTER_NAME)){
      filterCopy.delete(AFFORDABLE_FILTER_NAME)
      filtered = _.filter(filtered, power => power.xp <= character.xpToSpend)
    }
    if (filterCopy.size === 0){
      return filtered
    }
    filtered = _.filter(filtered, (item) => filterCopy.has(item.discipline))

    return filtered
  }

  return <>
    <PowerFilters
      powers={powers}
      filters={filters}
      flipFilter={flipFilter}
    />

    <table className={'char-table'}>
      <thead>
        <tr>
          <th>Name</th>
          <th>Bought</th>
          <th>Cost</th>
          <th>Plan</th>
        </tr>
      </thead>
      <tbody>
      {
        filteredPowers().map(power => <Power
          power={power}
          editPlanModal={openModalForPower}
        />)
      }
    </tbody></table>

      {planEditModal &&
      <PlanModal
        visible={planEditModal}
        charData={editingPowerData}
        close={() => setPlanEditModal(false)}
        plan={getPlan()}
        name={ editingPowerData.power.name }
        submit={addPlan}
        remove={removePlan}
      />}
    </>
}


const mapStateToProps = ({character, powers, checkedRequirements}) => {
  let checkedPowerRequirements = _.get(checkedRequirements, 'powerRequirementsMeetDataSet');
  return {
    character,
    powers,
    checkedPowerRequirements,
  }
};

const mapDispatchToProps = (dispatch) => ({
  getStaticData: (...args) => dispatch(getStaticData(...args)),
  getCharacter: (...args) => dispatch(getCharacter(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Powers);
