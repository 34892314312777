import {keycloak_logout_url, keycloak_url} from "./keycloakApi";

export default function logout() {
    let keycloakItems = [
      'expires_in',
      'not-before-policy',
      'refresh_token',
      'token_type',
      'scope',
      'refresh_expires_in',
      'id_token',
      'access_token',
      'session_state',
      'kc-callback-1f071940-ad3a-4e2e-b9ca-32146ec4e6ce',
      'access_token',
    ]
    for (const keycloakItem of keycloakItems){
      window.localStorage.removeItem(keycloakItem)
    }

    let keycloakCookies = [
        'KEYCLOAK_IDENTITY_LEGACY',
        'KEYCLOAK_IDENTITY',
        'KEYCLOAK_SESSION_LEGACY',
        'AUTH_SESSION_ID',
        'KEYCLOAK_SESSION',
        'AUTH_SESSION_ID_LEGACY',
    ]
    for (const cookieName of keycloakCookies){
        document.cookie = cookieName + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT;';
    }

    window.location.replace(keycloak_logout_url)
  }