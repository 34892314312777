import {Layout, List} from "antd";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import _ from "lodash";
import CharacterOrSelect from "./CharacterOrSelect";
import LoginComponent from "../Login/LoginComponent";
import * as React from "react";
import './styles.css'
import {useState} from "react";
const { Sider } = Layout;

function Sidebar({character}) {
  const [openHamburger, flipHamburger] = useState(false);
  let sideLinks = [
    {text: 'Non Modifiable', to: '/non-modifiable'},
    {text: 'XP independent', to: '/xp-independent'},
    {text: 'Elite Advances', to: '/elite-advances'},
    {text: 'Characteristics', to: '/characteristics'},
    {text: 'Skills', to: '/skills'},
    {text: 'Talents', to: '/talents'},
    character && _.find(character.eliteAdvances, {name: 'Psyker'})? {text: 'Special Advances', to: '/special-advances'} : null,
    {text: 'Plans', to: '/plans'},
    {text: 'Character Sheet', to: '/character_sheet'},
    {text: 'About', to: '/about'},
  ].filter(Boolean)

  sideLinks.push({component: <Link className={`${ !openHamburger ? 'display-none' : ''}`} to><LoginComponent/></Link>})

  return  <Sider className={'responsive-sider'}>
    <List
      size="large"
      className={`white-text responsive-list`}
      header={
        <CharacterOrSelect
          openHamburger={openHamburger}
          flipHamburger={flipHamburger}
        />
    }
      dataSource={sideLinks}
      renderItem={item => {
        if (item.component){
          return item.component
        }
        return <List.Item className={`${ !openHamburger ? 'display-none' : ''}`}>
          <Link to={item.to} onClick={()=> flipHamburger()}>{item.text}</Link>
        </List.Item>
      }}
    >
    </List>
  </Sider>
}


const mapStateToProps = ({character}) => {
  return {
    character,
  }
};


export default connect(
  mapStateToProps,
  null,
)(Sidebar);
