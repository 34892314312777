import _ from "lodash";
import {Button, Input} from "antd";
import {PlusSquareOutlined} from "@ant-design/icons";
import {getCharacter} from "../../redux/actions";
import {connect} from "react-redux";
import {useState} from "react";
import {saveCharacter} from "../../utils/fetcher";

function CustomTalent({character, talent, talents, talentsCosts, getCharacter}) {
  const [speciality, setSpeciality] = useState('');
  function handleAddSkill(){
    let char = _.cloneDeep(character);
    char.talents.push({
      talentId: talent.id,
      speciality,
      rank: 0,
    })
    saveCharacter(char, (char) => {
      getCharacter(char)
      setSpeciality('')
    })
  }

  if (!character || !talents || !talentsCosts || !talent){
      return null
  }

  return <tr>
    <td>{ talent.name }  <Input style={{width:'auto'}} value={speciality} onChange={(e) => setSpeciality(e.target.value)}/></td>
    <td></td>
    <td> <Button onClick={() => handleAddSkill()}><PlusSquareOutlined /> Add Talent</Button> </td>
  </tr>
}



const mapStateToProps = ({character, talentsCosts, talents}) => {
  return {
    character,
    talents,
    talentsCosts,
  }
};

const mapDispatchToProps = (dispatch) => ({
  getCharacter: (...args) => dispatch(getCharacter(...args)),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomTalent);
