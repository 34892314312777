import {connect} from "react-redux";
import {Collapse, List} from "antd";
import _ from 'lodash';

const { Panel } = Collapse;


function ChangesList({changes, characteristics, talents, skills}){
  return <Collapse
      className={`talent-title site-collapse-custom-collapse`}
    >
      <Panel
        header={'History 2'}
        key="1"
        classname={'site-collapse-custom-panel'}
      >
        <List
          style={{textAlign: 'left'}}
          size="small"
          bordered
          dataSource={changes}
          renderItem={(item) => {
            let changes = item.changeSet
            let characteriscticChanges = changes.characteristicChanges && changes.characteristicChanges.map(change =>
              _.find(characteristics, {id: change.id}).name
            )
            let skillChanges = changes.skillChanges && changes.skillChanges.map(change =>
              _.find(skills, {id: change.id}).name
            )
            let talentChanges = changes.talentChanges && changes.talentChanges.map(change =>
              _.find(talents, {id: change.id}).name
            )

            return <List.Item>
              Timestamp: {item.changeTimestamp}<br/>
              {changes.xpAdded &&
                <span>{`XP added: ${changes.xpAdded}`}</span>
              }
              {changes.corruptionChange &&
                <span>{`Corruption change: ${changes.corruptionChange}`}</span>
              }
              {characteriscticChanges &&
                <span>{`Characteristic change: ${characteriscticChanges}`}</span>
              }
              {skillChanges &&
                <span>{`Skills change: ${skillChanges}`}</span>
              }
              {talentChanges &&
                <span>{`Talents change: ${talentChanges}`}</span>
              }
              {
                // let changes = Object.entries(item.data).map(([field, changes]) => {
                //   return `${field}  ${changes.old_val ? 'from ' + changes.old_val[0] : ''} ${changes.new_val ? 'to ' + changes.new_val[0] + ', ' : ''}`
                // })
                // return <List.Item>
                //   {item.item_descr}: {item.event_type}; {changes} at {item.timestamp}
              }
            </List.Item>
          }
        }/>
      </Panel>
    </Collapse>
}

const mapStateToProps = ({changes, characteristics, talents, skills}) => {
  return {
    changes, characteristics, talents, skills
  }
};

export default connect(
  mapStateToProps,
)(ChangesList);