import _ from "lodash";
import {Button, Checkbox, Collapse} from "antd";
import {PlusSquareOutlined} from "@ant-design/icons";
import {getStaticData, getCharacter} from "../../redux/actions";
import {connect} from "react-redux";
import {saveCharacter} from "../../utils/fetcher";
import './styles.css';
const { Panel } = Collapse;

function PlanControl({charTalent, talentCost, talent, editPlanModal}){
  if (_.get(charTalent, 'plans[0]') !== undefined){
    return <div onClick={() => editPlanModal(talent, talentCost, _.get(charTalent, 'id'))}>Plan Priority: {_.get(charTalent, 'plans[0].priority')}</div>
  }
  return <Button onClick={() => editPlanModal(talent, talentCost, _.get(charTalent, 'id'))}><PlusSquareOutlined /> Add Plan</Button>
}


function Talent(
  {
    character,
    talent,
    charTalent,
    meetsRequirements,
    getCharacter,
    editPlanModal,
    talentCost,
  }) {

  function get_checkbox(character, talent, charTalent) {
    return <Checkbox
      checked={_.get(charTalent, 'howMany')}
      disabled={!meetsRequirements}
      onClick={() => {
        if (_.get(charTalent, 'howMany')) {
          handleRemoveTalent(character, talent, charTalent)
        } else {
          handleBuyTalent(character, talent, charTalent)
        }
      }
    }
    />
  }

  function handleBuyTalent(character, talent, charTalent) {
    if (talentCost > character.xpToSpend){
      alert(`Not enough xp to buy this talent. you need ${talentCost}`)
      return
    }

    let char = _.cloneDeep(character);
    charTalent = _.find(char.talents, {id:  _.get(charTalent, 'id')}) || {
      talentId: talent.id,
      plans: [],
    };
    charTalent.howMany = 1;
    charTalent.plans = [];
    char.xpSpent += talentCost
    char.xpToSpend -= talentCost
    // if charTalent has no id, has to be added to characters list of skills
    if (!charTalent.id){
      char.talents.push(charTalent)
    }
    saveCharacter(char, getCharacter)
  }

  const handleRemoveTalent = (character, talent, charTalent) => {
    let char = _.cloneDeep(character);
    charTalent = _.find(char.talents, {id: charTalent.id});
    if (charTalent.howMany <= charTalent.minimumhowMany){
      alert(`Minimum rank reached`)
      return
    }
    charTalent.howMany = 0
    char.xpSpent -= talentCost
    char.xpToSpend += talentCost
    saveCharacter(char, getCharacter)
  }

  const getBackgroundColor = (talent, character) => {
    let backgroundColors = ['talent-red', 'talent-orange', 'talent-green',]
    let matchingApptCnt = 0
    if (talent.firstAptitudeId in character.aptitudes.map(apt => apt.aptitudeId )  ){
      matchingApptCnt += 1
    }
    if (talent.secondAptitudeId in character.aptitudes.map(apt => apt.aptitudeId )  ){
      matchingApptCnt += 1
    }

    return backgroundColors[matchingApptCnt]
  }

  return <tr>
    <td style={{width: '390px'}}>
      <Collapse
        className={`talent-title site-collapse-custom-collapse ${getBackgroundColor(talent, character)} `}
      >
        <Panel
          header={`${talent.name} ${talent.isSpecialist && charTalent ? charTalent.speciality : ''}` }
          key="1"
          classname={'site-collapse-custom-panel'}
        >
          <p>{talent.description}</p>
          {talent.requirementsLabel &&
            <p>Requires: {talent.requirementsLabel}</p>
          }

        </Panel>
      </Collapse>
    </td>
    <td>{ get_checkbox(character, talent, charTalent) }</td>
    <td style={{textAlign: 'center'}}>{talentCost  }</td>
    <td>
      <PlanControl {...{charTalent, talentCost, talent, editPlanModal}}/>
    </td>
  </tr>
}



const mapStateToProps = ({character}) => {
  return {
    character,
  }
};

const mapDispatchToProps = (dispatch) => ({
  getStaticData: (...args) => dispatch(getStaticData(...args)),
  getCharacter: (...args) => dispatch(getCharacter(...args)),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Talent);
