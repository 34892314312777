import _ from "lodash";
import {Button, Input} from "antd";
import {PlusSquareOutlined} from "@ant-design/icons";
import {getCharacter} from "../../redux/actions";
import {connect} from "react-redux";
import {useState} from "react";
import {saveCharacter} from "../../utils/fetcher";

function Skill({character, skill, skills, skillsCosts, getCharacter, char_skill, editPlanModal}) {
  const [speciality, setSpeciality] = useState('');
  function handleAddSkill(){
    let char = _.cloneDeep(character);
    char.skills.push({
      skillId: skill.id,
      speciality,
      rank: 0,
    })
    saveCharacter(char, (char) => {
      getCharacter(char)
      setSpeciality('')
    })
  }

  if (!character || !skills || !skillsCosts || !skill){
      return null
  }

  let rank = char_skill ?  char_skill.rank : 0
  let skillCost = _.find(skillsCosts, {skillId: skill.id}).xpCostArray[rank];
  return <tr>
    <td>{ skill.name }  <Input style={{width:'auto'}} value={speciality} onChange={(e) => setSpeciality(e.target.value)}/></td>
    <td></td>
    <td>{skillCost}</td>
    <td> <Button onClick={() => handleAddSkill()}><PlusSquareOutlined /> Add Skill</Button> </td>
  </tr>
}



const mapStateToProps = ({character, skillsCosts, skills}) => {
  return {
    character,
    skills,
    skillsCosts,
  }
};

const mapDispatchToProps = (dispatch) => ({
  getCharacter: (...args) => dispatch(getCharacter(...args)),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Skill);
