import {getCharacter, getStaticData} from "../../../redux/actions";
import {connect} from "react-redux";
import Talent from "./TalentSML";
import _ from "lodash";
import './styles.css';
import '../items.css';
import {requiredProps} from "../../../utils/requiredProps";
import {Card} from "antd";
import mergeTalents from "../../../utils/mergeTalents";


function TalentsSML({mergedTalents, character}){

  if (requiredProps(character, mergedTalents)) {
    return 'prrrt'
  }

  const getFilteredTalents = () => {
    let filteredTalents = _.filter(mergedTalents, item => {
      return _.get(item, 'charTalent.howMany', 0) !== 0
    })

    return _.sortBy(filteredTalents, talent => talent.talent.name)
  }

  return <Card
    title={"Talents"}
    className={'talents-card'}
    size={'small'}
  >
    <table className='talents-table-sml erebus-talents-sml'>
      <thead>
        <tr>
          <th>Name</th>
        </tr>
      </thead>
      <tbody>{
        getFilteredTalents().map((mergedTalent, idx) =>{
          return <Talent key={idx} {...mergedTalent} />
      } ) }
      </tbody>
    </table>
  </Card>
}

const mapStateToProps = ({character, talents, checkedRequirements, talentsCosts}) => {
  let mergedTalents = []
  if (character && talents && checkedRequirements && talentsCosts ) {
    talents.map((talent) => mergeTalents(talent, character, checkedRequirements, mergedTalents, talentsCosts))
  }

  return {
    character,
    talentsCosts,
    mergedTalents
  }
};

const mapDispatchToProps = (dispatch) => ({
  getStaticData: (...args) => dispatch(getStaticData(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TalentsSML);
