import {getStaticData} from "../../redux/actions";
import {connect} from "react-redux";
import {Card, InputNumber} from "antd";
import _ from "lodash";
import {saveCharacterSheet} from "../../utils/auxiliaryCharacterFetcher";
import TextArea from "antd/es/input/TextArea";
import './items.css'

const debouncedSheetUpdate = _.debounce( (characterSheet, getStaticData) => {
  saveCharacterSheet(characterSheet, getStaticData)
  }, 2000
)


function Points({character, characterSheet, getStaticData}) {
  if (!character || !characterSheet) {
    return null;
  }

  const editSheetField = (value, field) => {
    let sheetCopy = _.cloneDeep(characterSheet)
    _.set(sheetCopy, field, value)
    getStaticData('characterSheet', sheetCopy);
    debouncedSheetUpdate(sheetCopy, getStaticData)
  }

  return <Card
    size="small"
    className={'skills-card'}
  >
    <InputNumber
      className='erebus-current-hp-input'
      style={{width: 150}}
      addonBefore="HP"
      value={characterSheet.current_hp}
      addonAfter={`/ ${character.fullWounds}`}
      onChange={e => editSheetField(e, 'current_hp')}
    />
    <InputNumber
      className='erebus-current-fp-input'
      style={{width: 150}}
      addonBefore="FP"
      value={characterSheet.current_fp}
      addonAfter={`/ ${character.fate}󠀠󠀠`}
      onChange={e => editSheetField(e, 'current_fp')}
    />
    <TextArea
      rows={8}
      value={characterSheet.notes}
      onChange={e => editSheetField(e.target.value, 'notes')}
    /><br/>
  </Card>
}
const mapStateToProps = ({character, characterSheet,}) => {
  return {
    character,
    characterSheet,
  }
};

const mapDispatchToProps = (dispatch) => ({
  getStaticData: (...args) => dispatch(getStaticData(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Points);