import {Col, Input} from "antd";
import * as React from "react";

function CardColInput({val, onChange, descr, descrSpan, valSpan, edit}){
  return <>
    <Col span={descrSpan}>
      {`${descr}`}:
    </Col>

    <Col span={valSpan}>
      {edit
        ? <Input
            className={'card-col-input-input'}
            size={'small'}
            value={val}
            onChange={onChange}
          />
        : val
      }
    </Col>
  </>
}
CardColInput.defaultProps = {
  descrSpan: 4,
  valSpan: 4,
  edit: false,
}
export default CardColInput