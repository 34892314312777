import {getDisciplines} from "./utils";
import FilterButton from "../FilterButton";

export const BOUGHT_FILTER_NAME = 'bought'
export const REQUIREMENTS_FILTER_NAME = 'requirement_met'
export const AFFORDABLE_FILTER_NAME = 'affordable'

export default function PowerFilters({powers, filters, flipFilter}) {
 let powerDisciplines = getDisciplines(powers)
 let disciplineFilters =  Array.from(powerDisciplines).map(discipline => <FilterButton
    filters={filters}
    filterText={discipline}
    filterName={discipline}
    flipFilter={flipFilter}
  />)

 return <>
  {disciplineFilters}
  <br/>
  <FilterButton
    filters={filters}
    filterText={'Bought'}
    filterName={BOUGHT_FILTER_NAME}
    flipFilter={flipFilter}
  />
  <FilterButton
    filters={filters}
    filterText={'Requirements met'}
    filterName={REQUIREMENTS_FILTER_NAME}
    flipFilter={flipFilter}
  />
  <FilterButton
    filters={filters}
    filterText={'Affordable'}
    filterName={AFFORDABLE_FILTER_NAME}
    flipFilter={flipFilter}
  />
 </>
}