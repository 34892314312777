import _ from "lodash";
import {fetcher} from "./fetcher";
import urls from "../config/backendUrls";
import {getCharacterSheet, getHistory, getItems} from "./auxiliaryCharacterFetcher";

export function getTalents(characterData, eliteAdvances, getStaticData) {
  let talentSets = [characterData.talentSet.name]
  characterData.eliteAdvances.forEach(advance => {
    talentSets.push(
      _.find(eliteAdvances, {name: advance.name}).talentSet
    )
  })

  fetcher({base: _.get(urls, 'talents'), talentSets: talentSets}, {method: 'get'})
  .then(data => {
    getStaticData('talents', data);
  })
}


export function getCharacterSpecificData(characterId, getStaticData) {
  // first get character
  fetcher({base: urls.character, id: characterId}, {method: 'get'})
    .then(characterData => {
      getStaticData('character', characterData);
      // other static data with weird params dependant on character details
      fetcher({base: urls.malignancies, mainTalentSet: characterData.talentSet.name}, {method: 'get'})
        .then(data => {
          getStaticData('malignancies', data);
        })
      fetcher({base: urls.mutations, mutationSet: characterData.mutationSet}, {method: 'get'})
        .then(data => {
          getStaticData('mutations', data);
        })
      // getStaticData('checkedRequirements', {powerRequirementsMeetDataSet: []})
      fetcher({base: urls.checkRequirements},
        {
          method: 'put',
          headers: {'content-type': 'application/json'},
          body: JSON.stringify(characterData)
        })
        .then(checkedReqs => {
          if (!checkedReqs) {
            getStaticData('checkedRequirements', {powerRequirementsMeetDataSet: []})
          } else {
            getStaticData('checkedRequirements', checkedReqs);
          }
        })
      fetcher({base: _.get(urls, 'eliteAdvances'), id: characterData.id}, {method: 'get'})
        .then(eliteAdvances => {
          getStaticData('eliteAdvances', eliteAdvances);
          getTalents(characterData, eliteAdvances, getStaticData)
          getItems(characterData.id, getStaticData)
          getHistory(characterData.id, getStaticData)
          getCharacterSheet(characterData.id, getStaticData)
        })
    })


  let characterSpecificEndpoints = [
    'skills', 'talentsCosts', 'skillsCosts', 'characteristicsCosts', 'powers', 'changes',
  ]
  characterSpecificEndpoints.forEach(endpoint => (
    fetcher({base: _.get(urls, endpoint), id: characterId}, {method: 'get'})
      .then(data => {
        getStaticData(endpoint, data);
      }))
  )
}

export function getStaticDataFromApi(getStaticData) {
  return fetcher({base: urls.charactersBasicData}, {method: 'get'})
    .then(data => {
      getStaticData('allCharacters', data);
      let nonSpecificEndpoints = [
        'characteristics', 'traits', 'aptitudes',
      ]
      Promise.all(
        nonSpecificEndpoints.map(endpoint => (
        fetcher({base: _.get(urls, endpoint)}, {method: 'get'})
          .then(data => {
            getStaticData(endpoint, data);
          }))
      )).then(brr2 => {
          let storageSelectedCharacterId = localStorage.getItem('selectedCharacterId')
          let foundChar = _.find(data, {id: parseInt(storageSelectedCharacterId)})
          if (foundChar){
            localStorage.setItem('selectedCharacterId', foundChar.id);
            getStaticData('selectedCharacter', foundChar)
            getCharacterSpecificData(foundChar.id, getStaticData)
          }
        })

    });
}