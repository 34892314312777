import {connect} from "react-redux";
import Characteristic from "./CharacteristicSML";
import _ from 'lodash';
import './styles.css';
function CharacteristicsSML({character}){

  return <div className={'characteristics-sml-container'}>{
    character.characteristics.map(char => <Characteristic key={char.id} char={char}/>)
  }</div>
}

const mapStateToProps = ({character}) => {
  return {
    character
  }
};

export default connect(
  mapStateToProps,
)(CharacteristicsSML);
