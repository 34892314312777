import {getStaticData} from "../../redux/actions";
import {connect} from "react-redux";
import {Card, Col, Input, InputNumber, Row} from "antd";
import _ from "lodash";
import * as React from "react";
import {useState} from "react";
import {SaveOutlined} from "@ant-design/icons";
import {createItem, getItems} from "../../utils/auxiliaryCharacterFetcher";
import TextArea from "antd/es/input/TextArea";
import CardColInput from "./CardColInput";


function NewCharacterVehicle({character, getStaticData, closeMe}) {
  let [item, setItem] = useState({
    name: null,
    description: null,
    count: 1,
    weight: null,
    character_id: character.id,
    vehicle_data: {
      crew: null,
      carrying_capacity: null,
      weapons: null,
      armour: null,
      tactical_speed: null,
      cruising_speed: null,
      integrity: null,
      traits: null,
    }
  })

  const editItemField = (e, field) => {
    let newItem = _.cloneDeep(item);
    _.set(newItem, field, e)
    setItem(newItem)
  }

  const saveNewItem = () => {
    createItem(item, getStaticData)
      .then(() => {
          getItems(character.id, getStaticData)
          closeMe()
        }
      )
  }

  let vehicle_data = item.vehicle_data;
  const descrSpan = 4
  const valSpan = 4
  const edit = true;

  return <Card
    size="small"
    title={<Input size={'small'}  value={item.name} onChange={(e) => editItemField(e.target.value, 'name')}/>}
    className={`weapon-card item-id-${item.id}`}
  >
    <Row>
      <CardColInput edit descr='Crew' descrSpan={descrSpan} valSpan={12} val={vehicle_data.crew} onChange={(e) => editItemField(e.target.value, 'vehicle_data.crew')}/>
      <CardColInput edit descr='Carrying capacity' val={vehicle_data.carrying_capacity} onChange={(e) => editItemField(e.target.value, 'vehicle_data.carrying_capacity')}/>
    </Row>
    <Row>
      <CardColInput edit descr='Armour'  val={vehicle_data.armour} onChange={(e) => editItemField(e.target.value, 'vehicle_data.armour')}/>
      <CardColInput edit descr='Type'  val={vehicle_data.type} onChange={(e) => editItemField(e.target.value, 'vehicle_data.type')}/>
      <CardColInput edit descr='Integrity'  val={vehicle_data.integrity} onChange={(e) => editItemField(e.target.value, 'vehicle_data.integrity')}/>
    </Row>
    <Row>
      <CardColInput edit descr='Tactical speed'  val={vehicle_data.tactical_speed} onChange={(e) => editItemField(e.target.value, 'vehicle_data.tactical_speed')}/>
      <CardColInput edit descr='Cruising speed'  val={vehicle_data.cruising_speed} onChange={(e) => editItemField(e.target.value, 'vehicle_data.cruising_speed')}/>
    </Row>
    <Row>
      Weapons:
    </Row>
    <Row>
      <TextArea
        autoSize
        value={vehicle_data.weapons}
        onChange={e => editItemField(e.target.value, 'vehicle_data.weapons')}
      />
    </Row>
    <Row>
      Description:
    </Row>
    <Row>
      <TextArea
        autoSize
        value={item.description}
        onChange={e => editItemField(e.target.value, 'description')}
      />
    </Row>
    <Row>
      Traits:
    </Row>
    <Row>
      <TextArea
        autoSize
        value={vehicle_data.traits}
        onChange={e => editItemField(e.target.value, 'vehicle_data.traits')}
      />
    </Row>
    <Row style={{justifyContent: 'end'}}><SaveOutlined style={{ fontSize: '25px' }} onClick={saveNewItem} /></Row>
  </Card>

}

const mapStateToProps = ({character}) => {
  return {
    character,
  }
};

const mapDispatchToProps = (dispatch) => ({
  getStaticData: (...args) => dispatch(getStaticData(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewCharacterVehicle);