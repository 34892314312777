import {connect} from "react-redux";
import _ from "lodash";
import './styles.css';

function CharacteristicSML({char, characteristics, characteristicsCosts}) {
  if (!char || !characteristics || !characteristicsCosts){
      return null
  }

  const char_characteristic = _.find(characteristics, {id: char.characteristicId})
  const name = _.get(char_characteristic, 'name');

  return <span className={`characteristics-sml-element char-sml-id-${char.characteristicId}`}>{name}: {char.value}</span>
}



const mapStateToProps = ({characteristicsCosts, characteristics, character}) => {
  return {
    character,
    characteristics,
    characteristicsCosts,
  }
};


export default connect(
  mapStateToProps,
)(CharacteristicSML);
