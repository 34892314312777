import {getStaticData} from "../../redux/actions";
import {connect} from "react-redux";
import {Card, Col, Input, Row} from "antd";
import _ from "lodash";
import * as React from "react";
import {deleteItem, getItems, saveItem} from "../../utils/auxiliaryCharacterFetcher";
import {DeleteOutlined, ScissorOutlined} from "@ant-design/icons";
import {useState} from "react";
import BaseItemColumns from "./BaseItemColumns";
import CardColInput from "./CardColInput";
import {CardTitle, CardTitleEdit} from "./CardTitle";
import TextArea from "antd/es/input/TextArea";


const debouncedEdit = _.debounce( (item, getStaticData) => {saveItem(item, getStaticData)}, 500)

function CharacterItem({item, characterItems, getStaticData, character}) {
  let [editActive, setEditActive] = useState(false);
  const toggleEdit = () => setEditActive(!editActive)

  const editField = (newValue, field) => {
    let itemsCopy = _.cloneDeep(characterItems);
    let itemCopy = _.find(itemsCopy, {id: item.id})
    itemCopy[field] = newValue
    getStaticData('characterItems', itemsCopy)
    debouncedEdit(itemCopy, getStaticData)
  }

  const removeItem = () => {
    if (window.confirm('Are you sure you want to delete this item?')){
      deleteItem(item, getStaticData)
      .then(() => {
          getItems(character.id, getStaticData)
        }
      )
    }

  }

  return <>
  <Card
    size="small"
    title={editActive ?
        <CardTitleEdit title={item.name} toggleEdit={toggleEdit} handleDelete={removeItem} onChange={(e) => editField(e.target.value, 'name')}/>
        :
        <CardTitle title={item.name} toggleEdit={toggleEdit}/>}
    className={`item-card item-id-${item.id}`}
  >
    <Row>
      <CardColInput edit={editActive} descr='Count'  val={item.count} onChange={(e) => editField(e.target.value, 'count')}/>
      <CardColInput edit={editActive} descr='Weight' val={item.weight} onChange={(e) => editField(e.target.value, 'weight')}/>
      <CardColInput edit={editActive} descr='Total'  val={item.combined_weight}/>
    </Row>
    {(item.description || editActive) &&
    <Row>
        { editActive ?
          <TextArea
            autoSize
            value={item.description}
            onChange={e => editField(e.target.value, 'description')}
          /> :
          <p>
            {item.description}
          </p>
        }
    </Row>
    }
  </Card>
  </>
}

const mapStateToProps = ({characterItems, character}) => {
  return {
    characterItems,
    character,
  }
};

const mapDispatchToProps = (dispatch) => ({
  getStaticData: (...args) => dispatch(getStaticData(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CharacterItem);
