import { getStaticData} from "../../redux/actions";
import {connect} from "react-redux";
import {Space} from "antd";
import EliteAdvance from "./EliteAdvance";

function EliteAdvances({character, eliteAdvances}){
  if (!character || !eliteAdvances){
    return 'bzzzt'
  }


  return <Space className={'EliteAdvances'} direction='vertical'>
    <>
    {
      eliteAdvances.map(adv => <EliteAdvance eliteAdvance={adv} /> )
    }</>
  </Space>
}


const mapStateToProps = ({character, eliteAdvances}) => {
  return {
    character,
    eliteAdvances,
  }
};

const mapDispatchToProps = (dispatch) => ({
  getStaticData: (...args) => dispatch(getStaticData(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EliteAdvances);
