import {getStaticData} from "../../redux/actions";
import {connect} from "react-redux";
import {Card, Col, Input, Row} from "antd";
import _ from "lodash";
import * as React from "react";
import {deleteItem, getItems, saveItem} from "../../utils/auxiliaryCharacterFetcher";
import {DeleteOutlined, ScissorOutlined} from "@ant-design/icons";
import {useState} from "react";
import './items.css'
import TextArea from "antd/es/input/TextArea";
import CardColInput from "./CardColInput";
import {CardTitle, CardTitleEdit} from "./CardTitle";

const debouncedEdit = _.debounce( (item) => {saveItem(item, getStaticData)}, 2000)

function CharacterItemWeapon({item, characterItems, getStaticData, character}) {
  let [edit, setEdit] = useState(false);

  const toggleEdit = () => setEdit(!edit)

  const editField = (newValue, field) => {
    let itemsCopy = _.cloneDeep(characterItems);
    let itemCopy = _.find(itemsCopy, {id: item.id})
    _.set(itemCopy, field, newValue)
    getStaticData('characterItems', itemsCopy)
    debouncedEdit(itemCopy, getStaticData)
  }

  const removeItem = () => {
    deleteItem(item, getStaticData)
      .then(() => {
          getItems(character.id, getStaticData)
        }
      )
  }

  let weapon_data = item.weapon_data;
  const descrSpan = 4

  return <Card
    size="small"
    title={edit ?<CardTitleEdit title={item.name} toggleEdit={toggleEdit} handleDelete={removeItem} onChange={(e) => editField(e.target.value, 'name')}/>
        :
        <CardTitle title={item.name} toggleEdit={toggleEdit}/> }
    className={`weapon-card item-id-${item.id}`}
  >
    <Row>
      <CardColInput edit={edit} descr='Damage' descrSpan={descrSpan} valSpan={12} val={weapon_data.damage} onChange={(e) => editField(e.target.value, 'weapon_data.damage')}/>
      <CardColInput edit={edit} descr='Pen' val={weapon_data.penetration} onChange={(e) => editField(e.target.value, 'weapon_data.penetration')}/>
    </Row>
    <Row>
      <CardColInput edit={edit} descr='Attacks'  val={weapon_data.attack_modes} onChange={(e) => editField(e.target.value, 'weapon_data.attack_modes')}/>
      <CardColInput edit={edit} descr='Type'  val={weapon_data.type} onChange={(e) => editField(e.target.value, 'weapon_data.type')}/>
      <CardColInput edit={edit} descr='Reload'  val={weapon_data.reload} onChange={(e) => editField(e.target.value, 'weapon_data.reload')}/>
    </Row>
    <Row>
      <CardColInput edit={edit} descr='Range'  val={weapon_data.range} onChange={(e) => editField(e.target.value, 'weapon_data.range')}/>
      <CardColInput edit={edit} descr='Clip size'  val={weapon_data.clip_size} onChange={(e) => editField(e.target.value, 'weapon_data.clip_size')}/>
    </Row>
    { (item.description || edit) &&
      <>
      <Row>
        Description:
      </Row>
      <Row>
        {edit ?
          <TextArea
            autoSize
            value={item.description}
            onChange={e => editField(e.target.value, 'description')}
          />  :
          <p>
            {item.description}
          </p>
        }


      </Row>
      </>
    }
  </Card>
}

const mapStateToProps = ({characterItems, character}) => {
  return {
    characterItems,
    character,
  }
};

const mapDispatchToProps = (dispatch) => ({
  getStaticData: (...args) => dispatch(getStaticData(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CharacterItemWeapon);