import { combineReducers } from "redux";
import { actionTypes } from "./actionTypes";

const initialState = {
  characteristics: [],
};

function mainReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_CHARACTERISTICS: {
      return {
        ...state,
        characteristics: action.payload.characteristics
      };
    }
    case actionTypes.GET_CHARACTER: {
      return {
        ...state,
        character: action.payload.character
      };
    }
    case actionTypes.GET_STATIC_DATA: {
      return {
        ...state,
        [action.field]: action.data
      };
    }

    default:
      return state;
  }
}

export default mainReducer; //combineReducers({ mainReducer });