import _ from 'lodash';
const backendPrefix = process.env.REACT_APP_BACKEND_URL
export const relativeUrls = {
  characteristics: `/StaticData/characteristics`,
  character: `/CharacterManagement/{id}`,
  characterSave: `/CharacterManagement/`,
  checkRequirements: `/CharacterManagement/checkRequirements/`,
  talents: `/StaticData/talents?{talentSets}`,
  // talents: `/StaticData/talents?talentSets=GregoDefault&talentSets=XenoHybris`, // narazie na sztywno
  skills: `/StaticData/skills`,
  traits: `/StaticData/traits`,
  aptitudes: `/StaticData/aptitudes`,
  talentsCosts: `/CharacterManagement/{id}/xpCost/talents`,
  skillsCosts: `/CharacterManagement/{id}/xpCost/skills`,
  characteristicsCosts: `/CharacterManagement/{id}/xpCost/characteristics`,
  charactersBasicData: `/CharacterManagement/charactersBasicData`,
  powers: `/StaticData/powers?powerSets=DH2Psychic&characterId={id}`,
  eliteAdvances: `/StaticData/eliteAdvances?characterId={id}`,
  malignancies: `/StaticData/malignancies?mainTalentSet={mainTalentSet}`,
  mutations: `/StaticData/mutations?mutationSet={mutationSet}`,
  changes: `/CharacterManagement/{id}/changes/`,
};

const urls = _.mapValues(relativeUrls, url => `${backendPrefix}${url}`);
export default urls;
