import {getStaticData} from "../../redux/actions";
import {connect} from "react-redux";
import {List} from "antd";
import _ from "lodash";


function Aptitudes({character, aptitudeDefinitions}){
  return <List.Item>
    <List.Item.Meta
      title='Aptitudes'
      description={character.aptitudes.map(apt => `${ _.find(aptitudeDefinitions, {id: apt.aptitudeId}).name}, ` )}
    />
  </List.Item>
}


function Traits({character, traitDefinitions}){
  return <List.Item>
    <List.Item.Meta
      title='Traits'
      description={character.traits.map(apt => `${ _.find(traitDefinitions, {id: apt.traitId}).name}, ` )}
    />
  </List.Item>
}

function CreationStep({character, type}){
  return <List.Item>
    <List.Item.Meta
      title={_.startCase(type)}
      description={
        _.filter(character.creationSteps, {type}).map(role => {
          if (role.bonus){
            return`${role.name}: ${role.bonus}`
          }
          return role.name
        })
      }
    />
  </List.Item>
}

function NonModifiable({character, aptitudes, traits}){
  if (!character || !aptitudes){
    return 'bzzzt'
  }
  return <div>
    <List>
      <List.Item>
        <List.Item.Meta
          className='erebus-xpSpent'
          title='XP spent'
          description={character.xpSpent}
        />
      </List.Item>
      <List.Item>
        <List.Item.Meta
          className='erebus-wounds'
          title='Wounds'
          description={character.fullWounds}
        />
      </List.Item>
      <List.Item>
        <List.Item.Meta
          className='erebus-fatePoints'
          title='Fate points'
          description={character.fate}
        />
      </List.Item>
      <Aptitudes
        character={character}
        aptitudeDefinitions={aptitudes}/>
      <Traits character={character} traitDefinitions={traits} />
      {
        character.creationSteps.map(step => <CreationStep
          character={character}
          type={step.type}
        />
        )
      }
      {/*<CreationStep character={character} type='Role'/>*/}
      {/*<CreationStep character={character} type='Divination'/>*/}
      {/*<CreationStep character={character} type='Background'/>*/}
      {/*<CreationStep character={character} type='Homeworld'/>*/}
    </List>
  </div>
}


const mapStateToProps = ({character, aptitudes, traits}) => {
  return {
    character,
    aptitudes,
    traits,
  }
};

const mapDispatchToProps = (dispatch) => ({
  getStaticData: (...args) => dispatch(getStaticData(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NonModifiable);
