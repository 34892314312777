import {connect} from "react-redux";
import './styles.css';


export default function PowerSML({power}) {
  return <tr>
    <td>
      {`${power.name}` }
    </td>
  </tr>
}
