import {actionTypes } from  './actionTypes';

export const getCharacteristics = characteristics => ({
    type: actionTypes.GET_CHARACTERISTICS,
    payload: { characteristics }
})
export const getCharacter = character => ({
    type: actionTypes.GET_CHARACTER,
    payload: { character }
})
export const getStaticData = (field, data) => ({
    type: actionTypes.GET_STATIC_DATA,
    field,
    data
})