import _ from 'lodash';
import urls from "../config/backendUrls";
import logout from "./logout";

import {NotificationManager} from 'react-notifications';

let nonBaseKeys = (urlData) => {
    let keys = _.keys(urlData);
    return _.filter(keys, item => item !== 'base')
}

export function isLoggedIn(){
    return !!window.localStorage.getItem('access_token')
}

export async function fetcher(urlData, args) {
    if (!window.localStorage.getItem('access_token')){
        console.log('i will not fetch without access token. Regards, fetcher.')
        NotificationManager.error('Error!', 'No access token.')
        return
    }
    _.set(args, 'headers.Authorization', `Bearer ${window.localStorage.getItem('access_token')}`)
    let url = urlData.base;
    nonBaseKeys(urlData).forEach(key => {
        let newParam = urlData[key]

        if(urlData[key].constructor.name == 'Array') {
            // if key has multiple values
            let multiParams = urlData[key].map(param => `${key}=${param}&`)
            newParam = multiParams.join('')
        }

        url = url.replace(`{${key}}`, newParam)
    })
    return await fetch(url, {
      ...args,
    })
    .then((response) => {
        if (response.ok){
            if (response.status !== 204){
                return response.json()
            }
            console.warn('No data!')
        }
        if (response.status === 401) {
            logout()
        }
        return response.json().catch(error => {
            if (_.get(error, 'errors') && _.get(error, 'title'))
            alert(
                error.title + '\n' +
                JSON.stringify(error.errors)
            )
            console.error(error);
        })
    })
}

export function saveCharacter(char, getCharacter) {
    fetcher({base: urls.characterSave}, {
        method: 'put',
        headers: {'content-type': 'application/json'},
        body: JSON.stringify(char)}
    ).then(data => {
        NotificationManager.success('Success!', 'Data saved successfully.')
        if (_.get(data, 'character')){
            getCharacter(data.character);
        }
    })
}
