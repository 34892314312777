import {getCharacter, getStaticData} from "../../redux/actions";
import {connect} from "react-redux";
import {Checkbox, Col, InputNumber, Row} from "antd";
import _ from "lodash";
import {useState} from "react";
import {saveCharacter} from "../../utils/fetcher";

const debouncedEditRank = _.debounce( (e, character, getCharacter, eliteAdvance, eliteAdvanceData, getStaticData) => {
  let charCopy = _.cloneDeep(character)
  let charEliteAdvance = _.find(charCopy.eliteAdvances, {name: eliteAdvance.name})
  charEliteAdvance.rating = e
  let xpCost = eliteAdvanceData.xpCost * (charEliteAdvance.rating-1)
  if (charCopy.xpToSpend < xpCost) {
    getStaticData('character', character)
    alert('Not enough xp!')
    return
  }
  charCopy.xpToSpend = charCopy.xpToSpend - xpCost
  charCopy.xpSpent = charCopy.xpSpent + xpCost
  saveCharacter(charCopy, getCharacter)
  }, 1000
)

function EliteAdvance({character, eliteAdvance, getCharacter, getStaticData}){

  let charEliteAdvance = _.find(character.eliteAdvances, {name: eliteAdvance.name})

  const [rank, setRank] = useState(_.get(charEliteAdvance, 'rating', 0));

  const editRank = (e) => {
    setRank(e)
    debouncedEditRank(e, character, getCharacter, charEliteAdvance, eliteAdvance, getStaticData)
  }

  const buyAdvance = () => {
    let charCopy = _.cloneDeep(character)
    charCopy.eliteAdvances.push({
      name: eliteAdvance.name
    })
    // charCopy.xpSpent += eliteAdvance.xpCost
    // charCopy.xpToSpend -= eliteAdvance.xpCost

    saveCharacter(charCopy, getCharacter)
  }

  const removeAdvance = () => {
    let charCopy = _.cloneDeep(character)
    _.remove(charCopy.eliteAdvances, {
      name: eliteAdvance.name
    })
    // charCopy.xpSpent += eliteAdvance.xpCost
    // charCopy.xpToSpend -= eliteAdvance.xpCost

    saveCharacter(charCopy, getCharacter)
  }

  const handleCheckboxChange = ({target} ) => {
    if (target.checked){
      if (window.confirm(`This operation is irreversible! \n Are you sure you want to buy: \n ${eliteAdvance.name} ?`)){
        buyAdvance()
      }
    } else {
      removeAdvance()
    }

  }


  return <Row className={'EliteAdvance'}>
    <Col span={6}>{eliteAdvance.name}</Col>
    <Col span={6}><Checkbox checked={charEliteAdvance} onChange={!charEliteAdvance && handleCheckboxChange}/></Col>
    <Col span={12}>{eliteAdvance.hasRating &&
      <InputNumber addonBefore="Rating" value={rank} onChange={editRank} />}
    </Col>
  </Row>
}


const mapStateToProps = ({character}) => {
  return {
    character,
  }
};

const mapDispatchToProps = (dispatch) => ({
  getStaticData: (...args) => dispatch(getStaticData(...args)),
  getCharacter: (...args) => dispatch(getCharacter(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EliteAdvance);
