import * as React from 'react'
import {useState} from 'react'

import {connect} from "react-redux";
import {getStaticData} from "../../redux/actions";
import {Col, Dropdown, Row, Select} from "antd";
import CharacterItem from "./CharacterItem";
import NewCharacterItem from "./NewCharacterItem";
import Points from "./Points";
import {ScissorOutlined} from "@ant-design/icons";
import NewCharacterItemWeapon from "./NewCharacterItemWeapon";
import HistoryList from "./HistoryList";
import CharacterItemWeapon from "./CharacterItemWeapon";
import Characteristics from "./CharacteristicsSML/CharacteristicsSML";
import SkillsSML from "./SkillsSML/SkillsSML";
import TalentsSML from "./TalentsSML/TalentsSML";
import NewCharacterVehicle from "./NewCharacterItemVehicle";
import CharacterItemVehicle from "./CharacterItemVehicle";
import PowersSML from "./PowersSML/PowersSML";
import ChangesList from "./ChangesList";

function CharacterChart({characterItems}) {
  let [openNewItem, setOpenNewItem] = useState(false);
  let [openNewType, setOpenNewItemType] = useState('item');

  if (!characterItems || !Array.isArray(characterItems)){
    return 'brr'
  }

  const toggleNewItem = () => {
    setOpenNewItem(!openNewItem)
  }

  let items = characterItems.filter(item => item.weapon_data === null)
  let weapons = characterItems.filter(item => item.weapon_data != null)
  let vehicles = characterItems.filter(item => item.vehicle_data != null)

  return <div style={{paddingLeft: '25px'}}>
    <Characteristics />
    <div className={'flex-container'}>
      <Points />
      <SkillsSML />
      <TalentsSML />
      <PowersSML />
    </div>
    <Row>
      {weapons.map(item => <CharacterItemWeapon item={item} key={item.id}/>)}
    </Row>
    <Row>
      {items.map(item => <CharacterItem item={item} key={item.id}/>)}
    </Row>
    <Row>
      {vehicles.map(item => <CharacterItemVehicle item={item} key={item.id}/>)}
    </Row>
    <Row>
      {/* <Col span={2}><button onClick={toggleNewWeapon}><ScissorOutlined /></button></Col>*/}
      {openNewItem && openNewType === 'weapon' && <NewCharacterItemWeapon closeMe={toggleNewItem} />}
      {openNewItem && openNewType === 'item' && <NewCharacterItem closeMe={toggleNewItem} />}
      {openNewItem && openNewType === 'vehicle' && <NewCharacterVehicle closeMe={toggleNewItem} />}
    </Row>
    <Row>
      {
        openNewItem && <Select
          defaultValue="item"
          style={{ width: 120 }}
          onChange={setOpenNewItemType}
          options={[
            { value: 'item', label: 'Item' },
            { value: 'weapon', label: 'Weapon' },
            { value: 'vehicle', label: 'Vehicle' },
          ]}
        />
      }
      <Col span={2}><button onClick={toggleNewItem}>+</button></Col>
    </Row>

    <HistoryList/>
    <ChangesList/>
  </div>
}

const mapStateToProps = ({character, characterItems}) => {
  return {
    character,
    characterItems,
  }
};

const mapDispatchToProps = (dispatch) => ({
  getStaticData: (...args) => dispatch(getStaticData(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CharacterChart);