import {Link} from "react-router-dom";
import * as React from "react";

export default function About(){
  return <div>
    <Link to={'/files/public/WH40kCharacterManager.zip'}>
      Public CharacterManager app download
    </Link>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    Unofficial WH40k Character Manager made by Grego & Sokator.
    Information contained in this application is mostly taken from Dark Heresy and Only War rulebooks and is a property of © Games Workshop.
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    Contact: grego.wh40k@gmail.com
  </div>
}
