import {getStaticData} from "../../redux/actions";
import {connect} from "react-redux";
import {Card, Col, Input, InputNumber, Row} from "antd";
import _ from "lodash";
import * as React from "react";
import {useState} from "react";
import {SaveOutlined} from "@ant-design/icons";
import {createItem, getItems} from "../../utils/auxiliaryCharacterFetcher";
import TextArea from "antd/es/input/TextArea";
import CardColInput from "./CardColInput";


function NewCharacterItem({character, getStaticData, closeMe}) {
  let [item, setItem] = useState({
    name: null,
    description: null,
    count: 1,
    weight: null,
    character_id: character.id,
    weapon_data: {
      damage: null,
      type: null,
      penetration: null,
      attack_modes: null,
      range: null,
      clip_size: null,
      reload: null,
      total_clips: null,
      current_clip: null,
      total_ammo: null,
    }
  })

  const editItemField = (e, field) => {
    let newItem = _.cloneDeep(item);
    _.set(newItem, field, e)
    setItem(newItem)
  }

  const saveNewItem = () => {
    createItem(item, getStaticData)
      .then(() => {
          getItems(character.id, getStaticData)
          closeMe()
        }
      )
  }

  let weapon_data = item.weapon_data;

  // return <Row>
  //   <Col span={3}><Input size={'small'}  value={item.name} onChange={(e) => editItemField(e.target.value, 'name')}/> </Col>
  //   <Col span={2}><Input size={'small'} value={weapon_data.damage} onChange={(e) => editItemField(e.target.value, 'weapon_data.damage')}/> </Col>
  //   <Col span={2}><Input size={'small'} value={weapon_data.penetration} onChange={(e) => editItemField(e.target.value, 'weapon_data.penetration')}/> </Col>
  //   <Col span={1}><Input size={'small'} value={weapon_data.type} onChange={(e) => editItemField(e.target.value, 'weapon_data.type')}/></Col>
  //   <Col span={1}><Input size={'small'} value={weapon_data.attack_modes} onChange={(e) => editItemField(e.target.value, 'weapon_data.attack_modes')}/></Col>
  //   <Col span={1}><InputNumber size={'small'} style={{width: 70}} value={weapon_data.range} onChange={(e) => editItemField(e, 'weapon_data.range')} /></Col>
  //   <Col span={1}><InputNumber size={'small'} style={{width: 70}} value={weapon_data.clip_size} onChange={(e) => editItemField(e, 'weapon_data.clip_size')} /></Col>
  //   <Col span={1}><InputNumber size={'small'} style={{width: 70}} value={weapon_data.reload} onChange={(e) => editItemField(e, 'weapon_data.reload')} /></Col>
  //   <Col span={1}><InputNumber size={'small'} style={{width: 70}} value={weapon_data.total_clips} onChange={(e) => editItemField(e, 'weapon_data.total_clips')} /></Col>
  //   <Col span={1}><InputNumber size={'small'} style={{width: 70}} value={weapon_data.current_clip} onChange={(e) => editItemField(e, 'weapon_data.current_clip')} /></Col>
  //   {/*<Col span={1}><InputNumber size={'small'} style={{width: 70}} value={weapon_data.total_ammo} onChange={(e) => editItemField(e, 'weapon_data.total_ammo')} /></Col>*/}
  //   <Col span={1}><InputNumber size={'small'} style={{width: 70}} value={item.weight} onChange={(e) => editItemField(e, 'weight')} /></Col>
  //   <Col span={1}> <SaveOutlined style={{ fontSize: '25px' }} onClick={saveNewItem} /> </Col>
  // </Row>

  const descrSpan = 4
  const valSpan = 4
  const edit = true;

  return <Card
    size="small"
    title={<Input size={'small'}  value={item.name} onChange={(e) => editItemField(e.target.value, 'name')}/>}
    className={`weapon-card item-id-${item.id}`}
  >
    <Row>
      <CardColInput edit descr='Damage' descrSpan={descrSpan} valSpan={12} val={weapon_data.damage} onChange={(e) => editItemField(e.target.value, 'weapon_data.damage')}/>
      <CardColInput edit descr='Pen' val={weapon_data.penetration} onChange={(e) => editItemField(e.target.value, 'weapon_data.penetration')}/>
    </Row>
    <Row>
      <CardColInput edit descr='Attacks'  val={weapon_data.attack_modes} onChange={(e) => editItemField(e.target.value, 'weapon_data.attack_modes')}/>
      <CardColInput edit descr='Type'  val={weapon_data.type} onChange={(e) => editItemField(e.target.value, 'weapon_data.type')}/>
      <CardColInput edit descr='Reload'  val={weapon_data.reload} onChange={(e) => editItemField(e.target.value, 'weapon_data.reload')}/>
    </Row>
    <Row>
      <CardColInput edit descr='Range'  val={weapon_data.range} onChange={(e) => editItemField(e.target.value, 'weapon_data.range')}/>
      <CardColInput edit descr='Clip size'  val={weapon_data.clip_size} onChange={(e) => editItemField(e.target.value, 'weapon_data.clip_size')}/>
    </Row>
    <Row>
      Description:
    </Row>
    <Row>
      <TextArea
        autoSize
        value={item.description}
        onChange={e => editItemField(e.target.value, 'description')}
      />
    </Row>
    <Row style={{justifyContent: 'end'}}><SaveOutlined style={{ fontSize: '25px' }} onClick={saveNewItem} /></Row>
  </Card>

}

const mapStateToProps = ({character}) => {
  return {
    character,
  }
};

const mapDispatchToProps = (dispatch) => ({
  getStaticData: (...args) => dispatch(getStaticData(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewCharacterItem);