import {Button, Checkbox, List} from "antd";
import {connect} from "react-redux";
import {getCharacter, getStaticData} from "../../redux/actions";
import _ from "lodash";
import './styles.css'
import {MinusSquareOutlined, PlusSquareOutlined} from "@ant-design/icons";
import {saveCharacter} from "../../utils/fetcher";


function get_class_name(charCosts) {
    if (!charCosts){
        return ''
    }

    const costs = charCosts.xpCostArray;

    let className = ''
    switch (costs[0]) {
      case 100:
        className = 'inner-green'
        break;
      case 250:
        className = 'inner-yellow'
        break;
      default:
        className = 'inner-red'
    }

    return className
}


function Influence({char, characteristics, characteristicsCosts, character, getCharacter, editPlanModal}) {
  if (!char || !characteristics || !characteristicsCosts){
      return null
  }

  const char_characteristic = _.find(characteristics, {id: char.characteristicId})
  const name = _.get(char_characteristic, 'name');

  function handleImproveChar(){
    let charCopy = _.cloneDeep(character);
    let charChar = _.find(charCopy.characteristics, {id: char.id})
    charChar.value += 1

    saveCharacter(charCopy, getCharacter)
  }

  function handleDecreaseChar() {
    let charCopy = _.cloneDeep(character);
    let charChar = _.find(charCopy.characteristics, {id: char.id})
    charChar.value -= 1

    saveCharacter(charCopy, getCharacter)
  }


  const openPlanModal = () => {

  }

  const charCosts = _.find(characteristicsCosts.xpData, {characteristicId: char.characteristicId})
  return <tr>
    <td>{name}</td>
    <td><Checkbox className={get_class_name(charCosts)}/></td>
    <td>{char.value}</td>
    <td><Button onClick={handleImproveChar}><PlusSquareOutlined /></Button>{'  '}<Button onClick={handleDecreaseChar}><MinusSquareOutlined /></Button></td>
    <td></td>
  </tr>
}



const mapStateToProps = ({characteristicsCosts, characteristics, character}) => {
  return {
    character,
    characteristics,
    characteristicsCosts,
  }
};

const mapDispatchToProps = (dispatch) => ({
  getStaticData: (...args) => dispatch(getStaticData(...args)),
  getCharacter: (...args) => dispatch(getCharacter(...args)),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Influence);
