import {getStaticData} from "../../redux/actions";
import {connect} from "react-redux";
import {Card, Col, Input, InputNumber, Row} from "antd";
import _ from "lodash";
import * as React from "react";
import {useState} from "react";
import {SaveOutlined} from "@ant-design/icons";
import {createItem, deleteItem, getItems, saveItem} from "../../utils/auxiliaryCharacterFetcher";
import TextArea from "antd/es/input/TextArea";
import CardColInput from "./CardColInput";
import {CardTitle, CardTitleEdit} from "./CardTitle";

const debouncedEdit = _.debounce( (item) => {saveItem(item, getStaticData)}, 2000)

function CharacterItemVehicle({item, character, getStaticData, characterItems}) {
  let [edit, setEdit] = useState(false);

  const toggleEdit = () => setEdit(!edit)

  const editField = (newValue, field) => {
    let itemsCopy = _.cloneDeep(characterItems);
    let itemCopy = _.find(itemsCopy, {id: item.id})
    _.set(itemCopy, field, newValue)
    getStaticData('characterItems', itemsCopy)
    debouncedEdit(itemCopy, getStaticData)
  }


  const removeItem = () => {
    if (window.confirm('Are you sure you want to delete this item?')){
      deleteItem(item, getStaticData)
      .then(() => {
          getItems(character.id, getStaticData)
        }
      )
    }

  }
  let vehicle_data = item.vehicle_data;
  const descrSpan = 4
  const valSpan = 4

  return <>
    <Card
      size="small"
      title={edit ?
        <CardTitleEdit title={item.name} toggleEdit={toggleEdit} handleDelete={removeItem} onChange={(e) => editField(e.target.value, 'name')}/>
        :
        <CardTitle title={item.name} toggleEdit={toggleEdit}/>}
      className={`item-card item-id-${item.id}`}
    >
      <Row>
        <CardColInput edit descr='Crew' descrSpan={descrSpan} valSpan={12} val={vehicle_data.crew} onChange={(e) => editField(e.target.value, 'vehicle_data.crew')}/>
        <CardColInput edit descr='Carrying capacity' val={vehicle_data.carrying_capacity} onChange={(e) => editField(e.target.value, 'vehicle_data.carrying_capacity')}/>
      </Row>
      <Row>
        <CardColInput edit descr='Armour'  val={vehicle_data.armour} onChange={(e) => editField(e.target.value, 'vehicle_data.armour')}/>
        <CardColInput edit descr='Type'  val={vehicle_data.type} onChange={(e) => editField(e.target.value, 'vehicle_data.type')}/>
        <CardColInput edit descr='Integrity'  val={vehicle_data.integrity} onChange={(e) => editField(e.target.value, 'vehicle_data.integrity')}/>
      </Row>
      <Row>
        <CardColInput edit descr='Tactical speed'  val={vehicle_data.tactical_speed} onChange={(e) => editField(e.target.value, 'vehicle_data.tactical_speed')}/>
        <CardColInput edit descr='Cruising speed'  val={vehicle_data.cruising_speed} onChange={(e) => editField(e.target.value, 'vehicle_data.cruising_speed')}/>
      </Row>
      <Row>
        Weapons:
      </Row>
      {(vehicle_data.weapons || edit) &&
        <Row>
          { edit ?
            <TextArea
              autoSize
              value={vehicle_data.weapons}
              onChange={e => editField(e.target.value, 'vehicle_data.weapons')}
            /> :
            <p>
              {vehicle_data.weapons}
            </p>
          }
        </Row>
      }
      {(item.description || edit) &&
        <Row>
          { edit ?
            <TextArea
              autoSize
              value={item.description}
              onChange={e => editField(e.target.value, 'description')}
            /> :
            <p>
              {item.description}
            </p>
          }
        </Row>
      }
      {(item.traits || edit) &&
        <Row>
          { edit ?
            <TextArea
              autoSize
              value={vehicle_data.traits}
              onChange={e => editField(e.target.value, 'vehicle_data.traits')}
            /> :
            <p>
              {vehicle_data.traits}
            </p>
          }
        </Row>
      }
    </Card>
  </>

}

const mapStateToProps = ({characterItems, character}) => {
  return {
    characterItems,
    character,
  }
};

const mapDispatchToProps = (dispatch) => ({
  getStaticData: (...args) => dispatch(getStaticData(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CharacterItemVehicle);