import {Button, Input, InputNumber, Modal} from "antd";
import {useState} from "react";

export default function PlanModal({visible, close, plan, name, submit, remove}) {
  const [priority, setPriority] = useState(plan.priority);
  const [comment, setComment] = useState(plan.comment);
  return <Modal
    title={`Edit plan for ${name}`}
    visible={visible}
    onCancel={close}
    footer={[
      <Button onClick={close}>Cancel</Button>,
      <Button onClick={() => submit(priority, comment)}>Save</Button>,
      <Button onClick={remove} danger>Remove</Button>,
    ]}
    destroyOnClose={true}
  >
    <InputNumber addonBefore="Priority" value={priority} onChange={setPriority} />
    <Input placeholder="Comment" value={comment} onChange={e => setComment(e.target.value)} />
  </Modal>
}