import _ from "lodash";
import {Button, Checkbox} from "antd";
import {PlusSquareOutlined} from "@ant-design/icons";
import {getStaticData, getCharacter} from "../../redux/actions";
import {connect} from "react-redux";
import {saveCharacter} from "../../utils/fetcher";

function PlanControl({char_skill, skillCosts, skill, editPlanModal}){
  if (char_skill){
    if (char_skill.rank === 4){
      return null
    }
    else if (_.get(char_skill, 'plans[0]') !== undefined){
      return <div onClick={() => editPlanModal(skill, skillCosts, _.get(char_skill, 'id'))}>Plan Priority: {_.get(char_skill, 'plans[0].priority')}</div>
    }
  }
  return <Button onClick={() => editPlanModal(skill, skillCosts, _.get(char_skill, 'id'))}><PlusSquareOutlined /> Add Plan</Button>
}


function Skill({character, skill, char_skill, skills, skillsCosts, getCharacter, editPlanModal}) {
  function get_checkboxes(skillsCosts, character, skill, char_skill) {
    let checkboxes = []
    for(let i=1; i<5; i++){
      checkboxes.push(
        <Checkbox
          checked={i <= _.get(char_skill, 'rank')}
          disabled={i > _.get(char_skill, 'rank', 0) + 1 || i < _.get(char_skill, 'rank', 0) }
          onClick={() => {
            if (i <= _.get(char_skill, 'rank')) {
              handleRemoveSkill(skillsCosts, character, skill, i, char_skill)
            } else {
              handleBuySkill(skillsCosts, character, skill, i, char_skill)
            }
          }
        }
        />)
    }
    return checkboxes
  }

  function handleBuySkill(skillsCosts, character, skill, idx, charSkill) {
    let skillCost = _.find(skillsCosts, {skillId: skill.id}).xpCostArray[idx-1];
    if (skillCost > character.xpToSpend){
      alert(`Not enough xp to buy this skill. you need ${skillCost}`)
      return
    }

    let char = _.cloneDeep(character);
    // lets confuse people and future me.
    // point being. the char skill we want to edit has to be from the cloned character object
    charSkill = _.find(char.skills, {id: _.get(charSkill, 'id')}) || {
      skillId: skill.id,
      plans: [],
    };
    charSkill.rank = idx;

    charSkill.plans = [];
    char.xpSpent += skillCost
    char.xpToSpend -= skillCost
    // if char_skill has no id, has to be added to characters list of skills
    if (!charSkill.id){
      char.skills.push(charSkill)
    }
    saveCharacter(char, getCharacter)
  }

  const handleRemoveSkill = (skillsCosts, character, skill, idx, charSkill) => {
    let skillCost = _.find(skillsCosts, {skillId: skill.id}).xpCostArray[idx-1];
    let char = _.cloneDeep(character);
    charSkill = _.find(char.skills, {id: charSkill.id});
    if (charSkill.rank <= charSkill.minimumRank){
      alert(`Minimum rank reached`)
      return
    }
    charSkill.rank -= 1
    char.xpSpent -= skillCost
    char.xpToSpend += skillCost
    // if char_skill has no id, has to be added to characters list of skills
    saveCharacter(char, getCharacter)
  }

  if (!character || !skills || !skillsCosts || !skill){
      return null
  }
  let rank = char_skill ?  char_skill.rank : 0
  let skillCosts = _.find(skillsCosts, {skillId: skill.id});
  let skillCost = skillCosts.xpCostArray[rank];

  return <tr>
    <td>{skill.name} { skill.isSpecialist && char_skill && char_skill.speciality }</td>
    <td>{ get_checkboxes(skillsCosts, character, skill, char_skill) }</td>
    <td>{skillCost}</td>
    <td><PlanControl {...{char_skill, skillCosts, skill, editPlanModal}}/>
    </td>

  </tr>
}



const mapStateToProps = ({character, skillsCosts, skills}) => {
  return {
    character,
    skills,
    skillsCosts,
  }
};

const mapDispatchToProps = (dispatch) => ({
  getStaticData: (...args) => dispatch(getStaticData(...args)),
  getCharacter: (...args) => dispatch(getCharacter(...args)),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Skill);
