import _ from 'lodash';
import logout from "./logout";

import {NotificationManager} from 'react-notifications';
const auxBackendPrefix = process.env.REACT_APP_AUXILIARY_BACKEND_URL

const relativeUrls = {
  character_sheet: `/characters/{character_id}/`,
  item_list: `/characters/{character_id}/equipment/`,
  history: `/characters/{character_id}/history/`,
  item_detail: `/characters/{character_id}/equipment/{item_id}/`,
};

const auxUrls = _.mapValues(relativeUrls, url => `${auxBackendPrefix}${url}`);

const baseHeaders = () => {
  return {
  Authorization: `Bearer ${window.localStorage.getItem('access_token')}`,
  'Content-Type': 'application/json',
}}


async function auxiliaryFetcher(url, args){
  if(!window.localStorage.getItem('access_token') || window.localStorage.getItem('access_token') === 'null'){
    console.warn('auxiliaryFetcher, no access token')
    NotificationManager.error('Error!', 'No access token.')
    return;
  }
  return await fetch(url, args)
    .then((response) => {
      if (response.ok){
          if (args.method && args.method !== 'GET'){
            NotificationManager.success('Success!', 'Operation completed.')
          }

        return response.json()
      }
      if (response.status === 401) {
          logout()
      }
      return response.json().catch(error => {
          NotificationManager.error('Error!', 'Error Error.')
          if (_.get(error, 'errors') && _.get(error, 'title'))
          alert(
              error.title + '\n' +
              JSON.stringify(error.errors)
          )
          console.error(error);
      })
    }).catch((error) => {
      NotificationManager.error('Error!', 'Error Error.')
      console.error(error);
    });
}

function get_url(url, {...params} ) {
  let requestUrl = _.cloneDeep(url);
  for (const key in params ){
    let newParam = params[key];
    if (newParam.constructor.name == 'Array'){
      let multiParams = params[key].map(param => `${key}=${param}&`)
      newParam = multiParams.join('')
    }
    requestUrl = requestUrl.replace(`{${key}}`, newParam)
  }

  return requestUrl
}

export async function saveItem(item, getStaticData){
  return await auxiliaryFetcher(
    get_url(auxUrls.item_detail, {character_id: item.character_id, item_id: item.id}),
    {
      method: 'PUT',
      headers: baseHeaders(),
      body: JSON.stringify(item),
    }
  )
  .then((response) => {
    getHistory(item.character_id, getStaticData)
    return response
  })
}

export async function createItem(item, getStaticData){
  return await auxiliaryFetcher(
    get_url(auxUrls.item_list, {character_id: item.character_id}),
    {
      method: 'POST',
      headers: baseHeaders(),
      body: JSON.stringify(item),
    }
  )
  .then((response) => {
    getHistory(item.character_id, getStaticData)
    return response
  })
  .catch(error =>  {                        // catch
    console.log('Request failed', error);
  });
}

export async function deleteItem(item, getStaticData){
  return await auxiliaryFetcher(
    get_url(auxUrls.item_detail, {character_id: item.character_id, item_id: item.id}),
    {
      method: 'DELETE',
      headers: baseHeaders(),
    }
  )
  .then((response) => {
    getHistory(item.character_id, getStaticData)
    return response
  })
  .catch(error =>  {                        // catch
    console.log('Request failed', error);
  });
}

export function getItems(character_id, getStaticData) {
  let url = get_url(auxUrls.item_list, {character_id})
  auxiliaryFetcher(
    url,
    {
      headers: baseHeaders()
    }
  )
  .then((data) => {
    getStaticData('characterItems', data);
  });
}

export function getHistory(character_id, getStaticData) {
  let url = get_url(auxUrls.history, {character_id})
  auxiliaryFetcher(
    url,
    {
      headers: baseHeaders()
    }
  )
  .then((data) => {
    getStaticData('characterHistory', data);
  });
}

export function getCharacterSheet(character_id, getStaticData) {
  let url = get_url(auxUrls.character_sheet, {character_id})
  auxiliaryFetcher(
    url,
    {
      headers: baseHeaders()
    }
  )
  .then((data) => {
    getStaticData('characterSheet', data);
  });
}

export async function saveCharacterSheet(characterSheet, getStaticData) {
  let url = get_url(auxUrls.character_sheet, {character_id: characterSheet.id})
  return await auxiliaryFetcher(
    url,
    {
      method: 'PUT',
      headers: baseHeaders(),
      body: JSON.stringify(characterSheet)
    }
  )
  .then((response) => {
    getHistory(characterSheet.id, getStaticData)
  })

}