import * as React from 'react'
import {connect} from "react-redux";
import {getStaticData} from "../../redux/actions";
import logout from "../../utils/logout";
import {keycloak_url} from "../../utils/keycloakApi";
import {Link} from "react-router-dom";
import {Button, Layout, Modal} from "antd";
import Sider from "antd/es/layout/Sider";
import {Content, Footer, Header} from "antd/es/layout/layout";

const LoginComponent = ({getStaticData}) => {

  console.log(process.env.BASE_URL)
  console.log(process.env)

  if (!window.localStorage.getItem('access_token'))
    return (<>
      <Layout>
        <Header>WH40k Character Manager</Header>
        <Content>
          <br/>
          <br/>
          <br/>
          <Button onClick={() => window.location.replace(keycloak_url)}>Login</Button>
          <br/>
          <br/>
          <br/>
        </Content>
        <Footer>
          <a href={`${process.env.REACT_APP_AUXILIARY_BACKEND_URL}/files/readme.html`}>
            Readme
          </a>
          <br/>
          <a href={`${process.env.REACT_APP_AUXILIARY_BACKEND_URL}/files/public/WH40kCharacterManager.zip`}>
            Public CharacterManager app download
          </a>

          <br/>
          Contact: grego.wh40k@gmail.com
        </Footer>
      </Layout>
    </>
    )
  return (
    <div onClick={logout}>
      Logout
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  getStaticData: (...args) => dispatch(getStaticData(...args)),
});

export default connect(
  null,
  mapDispatchToProps,
)(LoginComponent);
