import {getStaticData} from "../../../redux/actions";
import {connect} from "react-redux";
import {CheckOutlined} from "@ant-design/icons";


function Skill({character, skill, char_skill, skills, skillsCosts}) {
  if (!character || !skills || !skillsCosts || !skill){
      return null
  }
  let rank = char_skill ?  char_skill.rank : 0

  return <tr>
    <td>{skill.name} { skill.isSpecialist && char_skill && char_skill.speciality }</td>
    <td>{ rank > 1 ? `+${(rank-1)*10}`: <CheckOutlined /> }</td>
  </tr>
}

const mapStateToProps = ({character, skillsCosts, skills}) => {
  return {
    character,
    skills,
    skillsCosts,
  }
};

const mapDispatchToProps = (dispatch) => ({
  getStaticData: (...args) => dispatch(getStaticData(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Skill);
