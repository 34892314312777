import {getStaticData} from "../../redux/actions";
import {connect} from "react-redux";
import {Col, Input, InputNumber, Row} from "antd";
import _ from "lodash";
import * as React from "react";
import {useState} from "react";
import {SaveOutlined} from "@ant-design/icons";
import {createItem, getItems} from "../../utils/auxiliaryCharacterFetcher";


function NewCharacterItem({character, getStaticData, closeMe}) {
  let [item, setItem] = useState({name: null, description: null, count: null, weight: null, character_id: character.id })
  const editItemField = (e, field) => {
    let newItem = _.cloneDeep(item)
    newItem[field] = e
    setItem(newItem)
  }

  const saveNewItem = () => {
    createItem(item, getStaticData)
      .then(() => {
          getItems(character.id, getStaticData)
          closeMe()
        }
      )
  }

  return <Row>
    <Col span={3}><Input value={item.name} onChange={(e) => editItemField(e.target.value, 'name')}/> </Col>
    <Col span={4}><Input value={item.description} onChange={(e) => editItemField(e.target.value, 'description')}/> </Col>
    <Col span={1}><InputNumber value={item.count} onChange={(e) => editItemField(e, 'count')} /></Col>
    <Col span={1}><InputNumber value={item.weight} onChange={(e) => editItemField(e, 'weight')} /></Col>
    <Col span={1}></Col>
    <Col span={1}> <SaveOutlined style={{ fontSize: '25px' }} onClick={saveNewItem} /> </Col>
  </Row>
}

const mapStateToProps = ({character}) => {
  return {
    character,
  }
};

const mapDispatchToProps = (dispatch) => ({
  getStaticData: (...args) => dispatch(getStaticData(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewCharacterItem);